// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IDCol__iDCol-GRj{cursor:pointer}.IDCol__iDCol-GRj span{border-bottom:1px dashed #697077}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/IDCol/iDCol.module.scss"],"names":[],"mappings":"AAAA,kBACC,cAAA,CAEA,uBACC,gCAAA","sourcesContent":[".iDCol {\n\tcursor: pointer;\n\n\tspan {\n\t\tborder-bottom: 1px dashed #697077;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iDCol": `IDCol__iDCol-GRj`
};
export default ___CSS_LOADER_EXPORT___;
