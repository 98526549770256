import { Modal } from "@paykassma/pay-kit";
import AccountsBalancesContextProvider from "contexts/AccountsBalanceContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import { FC, useState } from "react";

import BalanceOperations from "./forms/BalanceOperations";
import CommissionReport from "./forms/CommissionReport";
import CompletedSumReport from "./forms/CompletedSumReport";
import LabelStatistics from "./forms/LabelStatistics";
import TransactionsCompletedList from "./forms/TransactionsCompletedList";
import TransactionsList from "./forms/TransactionsList";
import TransactionsSum from "./forms/TransactionsSum";
import Withdrawal from "./forms/Withdrawal";
import styles from "./ReportModal.module.scss";

type NewReportModalType = {
	readonly isOpen: boolean;
	readonly onClose: () => void;
};

const NewReportModal: FC<NewReportModalType> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const [activeFormId, setActiveFormId] = useState<string>(`transactions_sum`);
	const { hasRole } = useAuthContext();

	const REPORT_TYPES = [
		{
			id: "transactions_sum",
			title: t("Sum of transactions"),
			formComponent: TransactionsSum,
			hide: !hasRole(Roles.TRANSACTION_REPORTS),
		},
		{
			id: "transactions_list",
			title: t("Transaction list"),
			formComponent: TransactionsList,
			hide: !hasRole(Roles.TRANSACTION_REPORTS),
		},
		{
			id: "transactions_sum_with_completed_stockpiling",
			title: t("Transaction sum with completed stockpiling"),
			hide: !hasRole(Roles.TRANSACTION_REPORTS),
			formComponent: CompletedSumReport,
		},
		{
			id: "transactions_list_with_completed_stockpiling",
			title: t("Transaction list with completed stockpiling"),
			hide: !hasRole(Roles.TRANSACTION_REPORTS),
			formComponent: TransactionsCompletedList,
		},
		{
			id: "withdrawal_request_list",
			title: t("List of withdrawal requests"),
			formComponent: Withdrawal,
			hide: !hasRole(Roles.WITHDRAWAL_REQUEST_REPORT),
		},
		{
			id: "balance_history",
			title: t("History of displaying account data to a label"),
			formComponent: LabelStatistics,
			hide: !hasRole(Roles.LABEL_HISTORY_REPORT),
		},
		{
			id: "commissions_sum",
			title: t("Commission amount"),
			formComponent: CommissionReport,
			hide: !hasRole(Roles.COMMISSION_REPORT),
		},
		{
			id: "balance_operations",
			title: t("Operations with balances"),
			formComponent: BalanceOperations,
			hide: !hasRole(Roles.SETTLEMENT_OPERATIONS_REPORT),
		},
	];

	const ActiveForm = REPORT_TYPES.find((f) => f.id === activeFormId)?.formComponent;

	return (
		<AccountsBalancesContextProvider>
			<Modal isOpen={isOpen} title={t("Report type")} onClose={onClose} className={styles.modal}>
				<div className={styles.contentWrapper}>
					<nav>
						{REPORT_TYPES.filter((reportType) => !reportType.hide).map((reportType) => {
							const isActive = activeFormId === reportType.id;

							return (
								<button
									className={isActive ? styles.activeButton : undefined}
									key={reportType.id}
									data-test-id={reportType.id}
									onClick={() => setActiveFormId(reportType.id)}
								>
									{reportType.title}
									{isActive && (
										<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M4.37114e-07 10L17 7.43094e-07L17 20L4.37114e-07 10Z" fill="#F2F3F5" />
										</svg>
									)}
								</button>
							);
						})}
					</nav>
					{ActiveForm ? <ActiveForm /> : null}
				</div>
			</Modal>
		</AccountsBalancesContextProvider>
	);
};

export default NewReportModal;
