import API from "api";
import { StandardResponse } from "api/types";
import { WalletPriority } from "api/walletGroup";
import { Requisite } from "api/walletTypesGroup";
import he from "he";
import ChangeWalletModal from "modules/Wallets/components/modals/ChangeWalletModal";
import { useTranslation } from "pay-kit";
import { useEffect, useState } from "react";
import { ModalProps } from "utils/types";

type CreateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly actions: {
		readonly createWallet: (form: any) => Promise<StandardResponse<void>>;
	};
	readonly disabled: boolean;
};

export const CreateWalletModal = ({ closeModal, isOpen, actions, disabled }: CreateWalletModalProps) => {
	const { t } = useTranslation();
	const [respErrors, setErrors] = useState<any | null>(null);

	const clearExternalErrors = () => {
		setErrors(null);
	};

	useEffect(() => {
		if (!isOpen) {
			clearExternalErrors();
		}
	}, []);

	const initForm = {
		status: disabled ? `disabled` : `working`,
		priority: WalletPriority.NORMAL,
	};

	const onSuccess = () => {
		window.pushAlert({
			title: t("Success"),
			description: t("Wallet created"),
			type: "success",
		});
	};

	const onSubmit = (form: Partial<any>) => {
		const data = { ...form };

		if (data.display_identifier) {
			data.display_identifier = he.encode(data.display_identifier);
		}

		// if (data.account_number === "") {
		// 	delete data.account_number;
		// }
		return actions.createWallet({ ...data }).then((resp) => {
			if (resp.status === "error") {
				const errors = {...resp.errors} as unknown as Record<string, readonly string[]>;

				Object.keys(errors).forEach((field) => {
					const fieldError = errors[field];

					fieldError.forEach((error) => {
						window.pushAlert({
							title: t("Error"),
							description: error,
							type: "error",
						});
					});
				});

				if (Object.hasOwn(errors, "email_username")) {
					errors["email.email_username"] = errors.email_username;
					delete errors.email_username;
				}

				setErrors(errors);
			}

			return resp;
		});
	};

	const [requisites, setRequisites] = useState<readonly Requisite[]>([]);

	const fetchRequisites = (wallet_type: string) => {
		API.walletType
			.getManualPSRequisites(wallet_type)
			.then((resp) => {
				if (resp.status === "success") {
					setRequisites(resp.data.items);
				}

				return resp;
			})
			.catch((err) => console.error(err));
	};

	return (
		<ChangeWalletModal
			loading={false}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			initForm={initForm}
			closeModal={closeModal}
			actions={actions}
			isCreating
			externalErrors={respErrors}
			clearExternalErrors={clearExternalErrors}
			requisites={requisites}
			fetchRequisites={fetchRequisites}
			adminPanelType="instance"
		/>
	);
};

export default CreateWalletModal;
