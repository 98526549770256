import { TransferBalanceDataType } from "api/balanceGroup";
import QuestionMark from "assets/icons/qvestionMark.svg";
import AccountType from "modules/TransferHistory/components/TransferringMoney/components/AccountType";
import Amount from "modules/TransferHistory/components/TransferringMoney/components/Amount";
import Commission from "modules/TransferHistory/components/TransferringMoney/components/Commission";
import Crediting from "modules/TransferHistory/components/TransferringMoney/components/Crediting";
import Currency from "modules/TransferHistory/components/TransferringMoney/components/Currency";
import Rate from "modules/TransferHistory/components/TransferringMoney/components/Rate";
import useForm, { FormErrors, UseForm } from "modules/TransferHistory/components/TransferringMoney/hooks/useForm";
import { useOperationHistoryContext } from "modules/TransferHistory/contexts/OperationHistoryContext";
import {
	OperationHistoryModalContextType,
	useOperationHistoryModalContext,
} from "modules/TransferHistory/contexts/OperationHistoryModalContext";
import { useOperationHistoryTableContext } from "modules/TransferHistory/contexts/OperationHistoryTableContext";
import {
	convertIntoNumber,
	formatCrediting,
	formNumber,
	getCalcAmount,
	getCalcCrediting,
} from "modules/TransferHistory/helpers";
import { useTranslation } from "pay-kit";
import { Button, Tooltip } from "pay-kit";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import styles from "./transferringMoney.module.scss";

const TransferringMoney = () => {
	const { initForm, PSBalance, transferBalance } = useOperationHistoryModalContext();
	const operationHistoryTableAPI = useOperationHistoryTableContext();
	const { showTransferringModal } = useOperationHistoryContext();
	const { id } = useParams();
	const { t } = useTranslation();
	const [transferring, setTransferring] = useState(false);

	const schema = yup.object().shape({
		recipient_payment_system: yup.string().required(t("Required field")),
		ex_rate: formNumber(),
		writeoff_amount: formNumber(),
	});

	const { form, changeField, setForm, errors, submit } = useForm<OperationHistoryModalContextType["initForm"]>({
		initForm,
		schema,
		onSubmit: () => {
			setTransferring(true);
			return transferBalance({
				commission_percent: form.commission_percent,
				currency_code: form.currency_code,
				ex_rate: form.ex_rate,
				id: form.id,
				recipient_payment_system: form.recipient_payment_system,
				writeoff_amount: form.writeoff_amount,
			});
		},
		onSuccess: () => {
			showTransferringModal(false);
			window.pushAlert({
				type: `success`,
				title: t("Success"),
				description: t("Transfering was successful"),
			});
			setTransferring(false);
			operationHistoryTableAPI.onLoadOperationHistory();
		},
	});

	const isSettlement = form.recipient_payment_system === `paykassma`;

	useEffect(() => {
		setForm((prevState) => ({ ...prevState, id }));
	}, []);

	useEffect(() => {
		setForm((prevState) => ({
			...prevState,
			writeoff_amount: "",
			ex_rate: "",
		}));
	}, [form.currency_code]);

	const getAmount: GetAmountType = useCallback(
		(crediting, commission_rate, ex_rate) => {
			const getAmount = getCalcAmount(!!PSBalance?.is_settlement);

			return formatCrediting(getAmount({ commission_rate, ex_rate, crediting }));
		},
		[PSBalance?.is_settlement, form.ex_rate, form.commission_percent, form.crediting]
	);

	const getCrediting: GetCreditingType = useCallback(
		(amount, commission_rate, ex_rate) => {
			const getCrediting = getCalcCrediting(PSBalance?.is_settlement);

			return formatCrediting(getCrediting({ commission_rate, ex_rate, amount }));
		},
		[PSBalance?.is_settlement, form.ex_rate, form.commission_percent, form.crediting]
	);

	useEffect(() => {
		if (!PSBalance?.is_settlement) {
			setForm((prevState) => ({ ...prevState, currency_code: PSBalance?.currency_code }));
		}
	}, [PSBalance?.is_settlement]);

	const isDefaultValueOne = !PSBalance?.is_settlement && !isSettlement && parseInt(form.ex_rate as string) !== 1;

	useEffect(() => {
		if (isDefaultValueOne) {
			setForm((prev) => {
				return {
					...prev,
					ex_rate: "1",
				};
			});
		}
	}, [PSBalance?.is_settlement, form.ex_rate]);

	useEffect(() => {
		if (isSettlement) {
			setForm((prevState) => ({ ...prevState, currency_code: `USDT` }));
		}
	}, [form.recipient_payment_system]);

	useEffect(() => {
		let amount: number | string = getAmount(
			convertIntoNumber(form.crediting || ""),
			convertIntoNumber(form.commission_percent || ""),
			convertIntoNumber(form.ex_rate || "")
		);
		amount = Object.is(NaN, amount) ? "" : amount;
		if (form.isCreditingTouched) setForm((prevState) => ({ ...prevState, writeoff_amount: `${amount}` }));

		let credit: number | string = getCrediting(
			convertIntoNumber(form.writeoff_amount || ""),
			convertIntoNumber(form.commission_percent || ""),
			convertIntoNumber(form.ex_rate || "")
		);
		credit = Object.is(NaN, credit) ? "" : credit;
		if (form.isWriteOffAmountTouched) setForm((prevState) => ({ ...prevState, crediting: `${credit}` }));
	}, [
		form.ex_rate,
		form.commission_percent,
		form.crediting,
		form.writeoff_amount,
		form.isCreditingTouched,
		form.isWriteOffAmountTouched,
	]);

	const modalRows: readonly TransferringMoneyType[] = [
		{
			label: t("Type of account"),
			required: true,
			render: () => <AccountType name={"recipient_payment_system"} setForm={setForm} form={form} errors={errors} />,
		},
		{
			label: t("Currency"),
			required: true,
			render: () => <Currency name={"currency_code"} setForm={setForm} form={form} errors={errors} />,
		},
		{
			label: t("Amount"),
			required: true,
			toolTip: {
				hint: t("The amount that will be withdrawn from the balance account"),
			},
			render: () => <Amount name={"writeoff_amount"} setForm={setForm} form={form} errors={errors} />,
		},
		{
			label: t("Exchange rate"),
			required: true,
			toolTip: {
				hint: t("Exchange rate 1 CU"),
			},
			render: () => <Rate name={"ex_rate"} setForm={setForm} form={form} errors={errors} />,
		},
		{
			label: t("Commission %"),
			required: true,
			render: () => (
				<Commission
					name={"commission_percent"}
					setForm={setForm}
					form={form}
					errors={errors}
					isSettlement={isSettlement}
				/>
			),
		},
		{
			label: t("To crediting"),
			render: () => (
				<Crediting name={"crediting"} changeField={changeField} setForm={setForm} form={form} errors={errors} />
			),
		},
	];

	const renderRows = () => {
		return modalRows.map(({ label, required, toolTip, render, className, classNameGroup, hide }) => (
			<Fragment key={label}>
				{!hide && (
					<div className={[styles.modalRowContainer, className].join(" ")}>
						<div className={[styles.rowText, classNameGroup?.rowTextClass].join(" ")}>
							{label}
							{required && <span className={[styles.asterisk, classNameGroup?.asteriskClass].join(" ")}>*</span>}
							{toolTip && (
								<Tooltip tip={toolTip.hint} preferredSide="bottom">
									<span className={[styles.questionMark, classNameGroup?.questionMarkClass].join(" ")}>
										<QuestionMark />
									</span>
								</Tooltip>
							)}
						</div>
						{render()}
					</div>
				)}
			</Fragment>
		));
	};

	return (
		<div className={styles.transferringMoneyWrapper}>
			{renderRows()}
			<div className={styles.buttonWrapper}>
				<Button data-test-id="XlzcIvUyjXElwiopjWsCk" onClick={submit} isLoading={transferring}>
					{t("Transferring")}
				</Button>
			</div>
		</div>
	);
};

export default TransferringMoney;

type TransferringMoneyType = {
	readonly name?: string;
	readonly label: string;
	readonly required?: boolean;
	readonly toolTip?: {
		readonly hint: string;
	};
	readonly render: () => JSX.Element;
	readonly className?: string;
	readonly classNameGroup?: {
		readonly rowTextClass?: string;
		readonly asteriskClass?: string;
		readonly questionMarkClass?: string;
	};
	readonly hide?: boolean;
};

export type ModalRowComponentType = {
	readonly name: keyof TransferBalanceDataType;
	readonly changeField: UseForm<TransferBalanceDataType>[`changeField`];
	readonly setForm: UseForm<OperationHistoryModalContextType[`initForm`]>["setForm"];
	readonly form: Partial<OperationHistoryModalContextType[`initForm`]>;
	readonly errors: FormErrors<OperationHistoryModalContextType[`initForm`]>;
	readonly disabled?: boolean;
	readonly isSettlement?: boolean;
};

type GetAmountType = (crediting: number, commission_rate: number, ex_rate: number) => number;

type GetCreditingType = (amount: number, commission_rate: number, ex_rate: number) => number;
