import api from "api/walletGroup";
import { WalletListItem } from "api/walletGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

export type getWalletsResultType = {
	readonly load: (type?: string) => void;
	readonly list: readonly WalletListItem[];
	readonly isLoading: boolean;
};

export type getWalletsHookType = () => getWalletsResultType;

const useGetWalletsHook = () => {
	const [list, setList] = useState<readonly WalletListItem[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);

	const load = (
		type?: string,
		opts: { readonly removed?: 1 | 0; readonly is_active?: 1 | 0 } = { is_active: 1, removed: 0 }
	) => {
		setLoading(true);

		api
			.getList({ type, ...opts })
			.then((res: any) => {
				if (res.status !== "success" || !res?.data) {
					throw new Error(errorsMap.anyResponse);
				}

				setList(
					res.data.sort((a: WalletListItem, b: WalletListItem) => {
						if (a.is_active && !b.is_active) return -1;
						if (a.removed_at && !b.removed_at) return 1;
						if (!a.removed_at && b.removed_at) return -1;
						return 0;
					})
				);

				return res;
			})
			.finally(() => setLoading(false))
			.catch((err) => {
				console.error(err);
				window.pushAlert({ title: err, type: "error" });
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetWalletsHook;
