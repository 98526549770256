import { Table, useTranslation } from "@paykassma/pay-kit";
import { ITransactionsFilterForm } from "api/transactionGroup";
import { ICurrentTransaction } from "api/transactionGroup";
import ColumnTitleSeparator from "components/ColumnTitleSeparator";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import TextWithCopyButton from "components/TextWithCopyButton";
import { Roles } from "contexts/AuthContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import React, { useEffect, useState } from "react";
import { fromLocaleDate } from "utils/date";
import { toLocaleDate } from "utils/date";
import useURLState from "utils/hooks/useURLState";
import { Mutable } from "utils/types";

import ReloadButton from "../../Postbacks/components/ReloadButton";
import { currentTransactionsTableSubRow } from "../components/columns";
import useCurrentTransactionsListAPI from "../hooks/useCurrentTransactionsListAPI";
import ActionsProvider from "./ActionsProvider";
import CreateNewTransaction from "./CreateNewTransaction";
import styles from "./CurrentTransactions.module.scss";
import DetailedView from "./DetailedView";
import Filter from "./Filter";
import { getFilterInitState } from "../utils";
import { FilterErrors, isFilterInvalid, validateFilters } from "./Filter/utils";


const CurrentTransactions: React.FC = () => {
	const [page, setCurrentPage] = useURLState<number>("page", 1);
	const [limit, setLimit] = useURLState<number>("limit", 10);
	// Валидация фильтров
	const [filterErrors, setFilterErrors] = useState<FilterErrors>({
		transaction_id: null,
	});
	const [filter, setFilter] = useState<ITransactionsFilterForm>(getFilterInitState());
	const [detailedTransactionViewId, setDetailedTransactionViewId] = useState<number | string | null>(null);
	const { transactions, isLoading, error, paginationInfo, getTransactionsList } = useCurrentTransactionsListAPI();
	const loadTransactions = () => getTransactionsList({ limit, page, ...transformFilterData(filter) });

	const { t } = useTranslation();
	const { hasRole } = useAuthContext();
	const commissionAccess = hasRole(Roles.COMMISSION_VIEW);

	const getTheColumns = () => {
		const walletType = {
			title: t("Wallet type"),
			dataIndex: "wallet_type",
			key: "wallet_type",
			render: ({ wallet_type }: ICurrentTransaction) => <PaymentSystemLogo id={wallet_type} dataTestId="wallet_type" />,
		};

		const primaryId = {
			title: (
				<>
					ID <ColumnTitleSeparator /> {t("Wallet number")}
				</>
			),
			dataIndex: "primary_id",
			key: "primary_id",
			render: ({ primary_id, wallet_identifier }: ICurrentTransaction) => (
				<>
					<div data-test-id="primary_id">{primary_id}</div>
					<TextWithCopyButton
						className={styles.walletIdentifier}
						text={wallet_identifier}
						dataTestId="wallet_identifier"
					/>
				</>
			),
		};

		const amount = {
			title: (
				<>
					{t("Amount")}
					{commissionAccess && (
						<>
							<ColumnTitleSeparator /> {t("Commission")}
						</>
					)}
				</>
			),
			dataIndex: "amount",
			key: "amount",
			render: ({ amount, total_commission, direction, currency_code }: ICurrentTransaction) => (
				<>
					<Money direction={direction} amount={amount} currencyCode={currency_code} dataTestId="amount" />
					{commissionAccess && (
						<Money
							amount={total_commission}
							currencyCode={currency_code}
							className={styles.totalCommission}
							dataTestId="total_commission"
						/>
					)}
				</>
			),
		};

		const balance = {
			title: t("Balance"),
			dataIndex: "wallet_balance",
			key: "wallet_balance",
			render: ({ wallet_balance, currency_code }: ICurrentTransaction) =>
				wallet_balance !== undefined && (
					<Money amount={wallet_balance} currencyCode={currency_code} dataTestId="wallet_balance" />
				),
		};

		const createdAt = {
			// title: "Дата созд./актив.",
			title: t("Date created/activated"),
			dataIndex: "created_at",
			key: "created_at",
			render: ({ created_at, activated_at }: ICurrentTransaction) => (
				<>
					<div data-test-id="created_at">{toLocaleDate(created_at)}</div>
					<div className={styles.activatedAt} data-test-id="activated_at">
						{toLocaleDate(activated_at)}
					</div>
				</>
			),
		};

		const label = {
			title: (
				<>
					{t("Label")}
					<ColumnTitleSeparator /> {t("Order ID")}
				</>
			),
			dataIndex: "label",
			key: "label",
			render: ({ label, merchant_order_id }: ICurrentTransaction) => (
				<>
					<TextWithCopyButton textClassName={styles.label} text={label} dataTestId="label" />
					<TextWithCopyButton
						className={styles.merchantOrderIdBadge}
						textClassName={styles.merchantOrderId}
						text={merchant_order_id}
						dataTestId="merchant_order_id"
					/>
				</>
			),
		};

		const transactionNumber = {
			title: (
				<>
					{/* {t("Transaction number")}<ColumnTitleSeparator /> Контрагент */}
					{t("Transaction number")}
					<ColumnTitleSeparator /> {t("Counterparty")}
				</>
			),
			dataIndex: "id",
			key: "id",
			render: ({ id, exchanger_identifier }: ICurrentTransaction) => (
				<>
					<TextWithCopyButton text={id} dataTestId="id" />
					<div className={styles.exchangerIdentifier} data-test-id="exchanger_identifier">
						{exchanger_identifier}
					</div>
				</>
			),
		};

		return [
			walletType,
			primaryId,
			amount,
			hasRole(Roles.WALLET_BALANCE) && balance,
			createdAt,
			label,
			transactionNumber,
		];
	};

	useEffect(loadTransactions, [limit, page, filter]);

	const detailedTransactionViewData = detailedTransactionViewId
		? transactions.find((transaction) => transaction.primary_id === detailedTransactionViewId)
		: null;

	return (
		<ActionsProvider onSuccess={loadTransactions}>
			<div className={styles.actions}>
				<CreateNewTransaction onSuccess={loadTransactions} />
			</div>
			<DetailedView
				transactionData={detailedTransactionViewData}
				onClose={() => setDetailedTransactionViewId(null)}
				onFilterApply={(filterValues: ITransactionsFilterForm) => {
					setDetailedTransactionViewId(null);
					setCurrentPage(1);
					setFilter({ ...filter, ...filterValues });
				}}
			/>
			<Filter
				value={filter}
				errors={filterErrors}
				setErrors={setFilterErrors}
				onApply={(value: Mutable<ITransactionsFilterForm>) => {
					const errors = validateFilters(value as FilterErrors);

					setFilterErrors(errors);

					if (isFilterInvalid(errors)) {
						return;
					}

					setCurrentPage(1);
					setFilter(value);
				}}
			/>
			<Table
				className={hasRole(Roles.WALLET_BALANCE) ? styles.CurrentTransactionsTable : ""}
				columns={getTheColumns()}
				data={transactions}
				rowKey="primary_id"
				message={error ? { text: `${error}`, type: "error" } : undefined}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				onRowClick={setDetailedTransactionViewId}
				stickyHead
				subRowRender={currentTransactionsTableSubRow}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.current_page || 1,
								setCurrentPage: (page: number) => setCurrentPage(page as number),
								limit,
								setLimit,
								lastAvailablePage: paginationInfo?.last_available_page || 1,
								prefixElement: <ReloadButton data-test-id="" isLoading={isLoading} onClick={loadTransactions} />,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
						  }
						: undefined
				}
			/>
		</ActionsProvider>
	);
};

const transformFilterData = (filterData: Mutable<ITransactionsFilterForm>) => {
	const data = { ...filterData };
	const dateFields: ReadonlyArray<keyof ITransactionsFilterForm> = [
		`date_to`,
		`date_from`,
		`activated_date_from`,
		`activated_date_to`,
	];
	dateFields.forEach((part) => {
		if (data[part]) {
			data[part] = fromLocaleDate(data[part]);
		}
	});

	return data;
};

export default CurrentTransactions;
