import { ModalRowComponentType } from "modules/TransferHistory/components/TransferringMoney";
import useDebounce from "modules/TransferHistory/components/TransferringMoney/hooks/useDebounce";
import useGetSettlementCommission from "modules/TransferHistory/components/TransferringMoney/hooks/useGetSettlementCommission";
import { testAgainstNonNumbers } from "modules/TransferHistory/helpers";
import { TextInput } from "pay-kit";
import { ChangeEvent, FC, useEffect } from "react";

import styles from "./commission.module.scss";

const Commission: FC<Omit<ModalRowComponentType, "changeField">> = ({
	name,
	setForm,
	form,
	errors,
	disabled,
	isSettlement,
}) => {
	const GetSettlementCommissionAPI = useGetSettlementCommission();

	const debouncedValue = useDebounce({ id: form?.id, writeoff_amount: form?.writeoff_amount }, 3000);

	useEffect(() => {
		if (debouncedValue?.id && debouncedValue?.writeoff_amount && isSettlement) {
			GetSettlementCommissionAPI.load({ id: debouncedValue.id, amount: debouncedValue.writeoff_amount });
		}
	}, [debouncedValue?.id, debouncedValue?.writeoff_amount, isSettlement]);

	useEffect(() => {
		if (GetSettlementCommissionAPI.data?.percent_commission) {
			setForm((form) => ({ ...form, [name]: GetSettlementCommissionAPI.data?.percent_commission }));
		}
	}, [GetSettlementCommissionAPI.data?.percent_commission]);

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = testAgainstNonNumbers(form[name] || "", e.target.value, 2);
		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		if (!isSettlement) {
			setForm((prevState) => ({
				...prevState,
				[name]: "0",
			}));
		}
	}, [form.ex_rate]);

	useEffect(() => {
		if (isSettlement) {
			setForm((prevState) => ({
				...prevState,
				[name]: "0",
			}));
		}
	}, [form.recipient_payment_system]);

	return (
		<div className={styles.commissionTextInput}>
			<TextInput
				name={name}
				className={styles.inputContainer}
				value={`${form[name]}`}
				onChange={handleOnChange}
				isDisabled={!form.recipient_payment_system}
				error={errors[name]}
			/>
		</div>
	);
};

export default Commission;
