import { AccountsBalanceDateType } from "api/balanceGroup";
import ArrowLeft from "assets/icons/arrowLeft.svg";
import { useAccountsBalancesContext } from "contexts/AccountsBalanceContext";
import OperationHistory from "modules/TransferHistory/components/OperationHistory";
import OperationHistoryContextProvider, {
	useOperationHistoryContext,
} from "modules/TransferHistory/contexts/OperationHistoryContext";
import { useTranslation } from "pay-kit";
import { Button, Loader } from "pay-kit";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./transferHistory.module.scss";

const TransferHistory = () => {
	const operationHistoryContextAPI = useOperationHistoryContext();
	const { getAccountsBalanceHistoryByDate } = useAccountsBalancesContext();
	const { date, time } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const filter = date && time ? { date: `${date} ${time}` } : {};
		getAccountsBalanceHistoryByDate(filter as AccountsBalanceDateType);
	}, []);

	return (
		<div>
			<Button
				data-test-id="accounts-balance"
				onClick={() => navigate(`/balances/accounts`, { replace: true })}
				classname={styles.goBackButton}
			>
				<ArrowLeft /> {t("Accounts balance")}{" "}
			</Button>
			<div className={styles.transactionHistoryHeader}>{t("Operation history")}</div>
			{!operationHistoryContextAPI.filteredBalances.length && (
				<div className={styles.loading}>
					<Loader color="#A2A9B0" />
				</div>
			)}
			<OperationHistory />
		</div>
	);
};

export default () => (
	<OperationHistoryContextProvider>
		<TransferHistory />
	</OperationHistoryContextProvider>
);
