import API from "api";
import { GetAccountsBalanceHistoryType, PSBalanceType, Withdrawals } from "api/balanceGroup";
import { Error } from "api/types";
import { WalletType } from "api/walletTypesGroup";
import { useAccountsBalancesContext } from "contexts/AccountsBalanceContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import styles from "modules/TransferHistory/components/OperationHistory/operationHistory.module.scss";
import Replenish from "modules/TransferHistory/components/Replenish";
import TransferringMoney from "modules/TransferHistory/components/TransferringMoney";
import Withdrawal from "modules/TransferHistory/components/Withdrawal";
import { gettingCurrentCurrencyCode } from "modules/TransferHistory/contexts/OperationHistoryContext/helpers";
import OperationHistoryModalContextProvider from "modules/TransferHistory/contexts/OperationHistoryModalContext";
import { useTranslation } from "pay-kit";
import { Modal } from "pay-kit";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import OperationHistoryTableContextProvider from "../OperationHistoryTableContext";

export const OperationHistoryContext = createContext<OperationHistoryContextType>({} as OperationHistoryContextType);

const OperationHistoryContextProvider = ({ children }: { readonly children: ReactNode }) => {
	const { t } = useTranslation();
	const { id, ps } = useParams();
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { filteredBalances } = useAccountsBalancesContext();
	const [openTransferringModal, setOpenTransferringModal] = useState(false);
	const [openReplenishModal, setOpenReplenishModal] = useState(false);
	const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
	const { walletTypes, isLoading: isWalletTypesLoading } = useContext(WalletTypesContext);
	const [withdrawalList, setWithdrawalList] = useState<readonly Withdrawals[]>([]);

	const PSBalance = gettingCurrentCurrencyCode(id, ps, filteredBalances);

	const getWithdrawalsList = () => {
		setIsLoading(true);
		setError(null);

		API.balances
			.getWithdrawalsList()
			.then((res) => {
				if (res.status === "ok") {
					setWithdrawalList(res.withdrawals);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const rejectApproveNewWithdrawal = (id: number, type: string) => {
		setIsLoading(true);
		setError(null);
		API.balances
			.rejectApproveNewWithdrawal(id, type)
			.then((res) => {
				if (res.status === "ok") {
					getWithdrawalsList();
				}

				if (res.status === "fail") {
					window.pushAlert({
						type: "error",
						description: `${res.message}`,
					});
					setError(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const contextValue = {
		rejectApproveNewWithdrawal,
		getWithdrawalsList,
		withdrawalList,
		showReplenishModal: setOpenReplenishModal,
		openWithdrawModal: setOpenWithdrawModal,
		showTransferringModal: setOpenTransferringModal,
		filteredBalances,
		walletTypes,
		PSBalance,
		isWalletTypesLoading,
		isLoading,
		error,
	};

	return (
		<OperationHistoryContext.Provider value={contextValue}>
			<OperationHistoryTableContextProvider>
				<>
					{children}
					<OperationHistoryModalContextProvider>
						<Modal
							title={t("Replenishment from the client's external account")}
							onClose={() => setOpenReplenishModal((open) => !open)}
							isOpen={openReplenishModal}
						>
							<Replenish />
						</Modal>

						<Modal
							title={t("Withdrawal to the client's external account")}
							onClose={() => setOpenWithdrawModal((open) => !open)}
							isOpen={openWithdrawModal}
						>
							<Withdrawal />
						</Modal>

						<Modal
							title={t("Transferring money to another account balance")}
							onClose={() => setOpenTransferringModal((open) => !open)}
							isOpen={openTransferringModal}
						>
							<TransferringMoney />
						</Modal>
					</OperationHistoryModalContextProvider>
				</>
			</OperationHistoryTableContextProvider>
		</OperationHistoryContext.Provider>
	);
};
export default OperationHistoryContextProvider;

export const useOperationHistoryContext = () => useContext(OperationHistoryContext);

type OperationHistoryContextType = {
	readonly rejectApproveNewWithdrawal: (id: number, type: string) => void;
	readonly getWithdrawalsList: () => void;
	readonly withdrawalList: readonly Withdrawals[];
	readonly showReplenishModal: Dispatch<SetStateAction<boolean>>;
	readonly openWithdrawModal: Dispatch<SetStateAction<boolean>>;
	readonly showTransferringModal: Dispatch<SetStateAction<boolean>>;
	readonly filteredBalances: GetAccountsBalanceHistoryType[`balances`];
	readonly walletTypes: readonly WalletType[];
	readonly PSBalance: PSBalanceType | undefined;
	readonly isWalletTypesLoading: boolean;
	readonly isLoading: boolean;
	readonly error: Error | null;
};
