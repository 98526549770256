import { WalletListItem } from "api/walletGroup";
import { WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import { Badge, Tooltip, useTranslation } from "pay-kit";
import { ComponentProps } from "react";

import styles from "./StatusBadges.module.scss";

type StatusBadgesProps = {
	readonly type?: WalletListItem["type"];
	readonly state: WalletListItem[`state`];
	readonly dataTestId?: string;
};

const StatusBadges = ({ state, type, dataTestId }: StatusBadgesProps) => {
	const {t} = useTranslation();
	const variantMapping: Record<WalletState, ComponentProps<typeof Badge>["variant"]> = {
		[WalletState.ALLOWED_BALANCE_EXCEEDED]: `orange`,
		[WalletState.AUTHORISATION_ERROR]: `red`,
		[WalletState.INTEGRATION_ERROR]: `orange`,
		[WalletState.PROBABLY_BANNED]: `red`,
		[WalletState.PROXY_NOT_WORKING]: `red`,
		[WalletState.ERROR_IN_TRANSACTION_PARSING]: `red`,
		[WalletState.PARSER_ERROR]: `red`,
		[WalletState.NO_COOKIES]: `red`,
		[WalletState.PENDING_SMS_CONFIRMATION]: `blue`,
		[WalletState.UPDATE_COOKIES]: `orange`,
		[WalletState.ACCOUNT_DATA_ERROR]: `red`,
		[WalletState.UNKNOWN_ERROR]: `red`,
		[WalletState.DAILY_LIMIT_EXCEEDED]: `orange`,
		[WalletState.DATA_ACCESS_ERROR]: `red`,
		[WalletState.PK_CONNECTION_ERROR]: `red`,
		[WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED]: `orange`,
		[WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED]: `orange`,
		[WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED]: `orange`,
	};

	const textMapping: Record<WalletState, string> = {
		[WalletState.ALLOWED_BALANCE_EXCEEDED]: t("Balance exceeded"),
		[WalletState.AUTHORISATION_ERROR]: t("Auth Error"),
		[WalletState.INTEGRATION_ERROR]: t("Integration error"),
		[WalletState.PROBABLY_BANNED]: t("Probably banned"),
		[WalletState.PROXY_NOT_WORKING]: t("Proxy isn't working"),
		[WalletState.ERROR_IN_TRANSACTION_PARSING]: t("Error transaction parsing"),
		[WalletState.PARSER_ERROR]: t("Parser error"),
		[WalletState.NO_COOKIES]: t("No cookies"),
		[WalletState.PENDING_SMS_CONFIRMATION]: t("Pending SMS confirmation"),
		[WalletState.UPDATE_COOKIES]: t("Update cookies"),
		[WalletState.ACCOUNT_DATA_ERROR]: t("Invalid date"),
		[WalletState.UNKNOWN_ERROR]: t("Unknown error"),
		[WalletState.DAILY_LIMIT_EXCEEDED]: t("Daily limit on the amount exceeded"),
		[WalletState.DATA_ACCESS_ERROR]: t("Data access error"),
		[WalletState.PK_CONNECTION_ERROR]: t("PK connection error"),
		[WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED]: t("Monthly turnover limit exceeded"),
		[WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED]: t("Daily turnover limit exceeded"),
		[WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED]: t("Daily transaction limit exceeded"),
	};

	return (
		<div className={styles.wrapper} data-test-id={dataTestId}>
			{state?.map((status, id) => {
				const text = textMapping[status];

				return (
					<Tooltip tip={text} key={id}>
						<Badge variant={variantMapping[status]}>{text}</Badge>
					</Tooltip>
				);
			})}
		</div>
	);
};

export default StatusBadges;
