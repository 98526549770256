import { Tooltip, useTranslation } from "@paykassma/pay-kit";
import { WalletPriority } from "api/walletGroup";
import { priorityMappingWithDescription } from "modules/Wallets/components/modals/ChangeWalletModal/hooks/useWalletData";
import { useMemo } from "react";

import AbovePriorityIcon from "./assets/above.svg";
import BelowPriorityIcon from "./assets/below.svg";
import HighPriorityIcon from "./assets/high.svg";
import LowPriorityIcon from "./assets/low.svg";
import NormalPriorityIcon from "./assets/normal.svg";

type PriorityProps = {
	readonly priority: number;
	readonly dataTestId?: string;
};

const Priority = ({ priority, dataTestId }: PriorityProps) => {
	const priorityIcon = useMemo(() => {
		if (priority === WalletPriority.LOW) {
			return <LowPriorityIcon />;
		} else if (priority === WalletPriority.BELOW_NORMAL) {
			return <BelowPriorityIcon />;
		} else if (priority === WalletPriority.NORMAL) {
			return <NormalPriorityIcon />;
		} else if (priority === WalletPriority.ABOVE_NORMAL) {
			return <AbovePriorityIcon />;
		}

		return <HighPriorityIcon />;
	}, [priority]);
	const { t } = useTranslation();
	return (
		<Tooltip tip={priorityMappingWithDescription[priority]}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
				data-test-id={dataTestId}
			>
				{priorityIcon}
			</div>
		</Tooltip>
	);
};

export default Priority;
