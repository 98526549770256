import { PayKitForm } from "@paykassma/pay-kit";
import { useDashboardContext } from "contexts/DashboardContext";
import { formatDate, getDashboardFiltersInitPrams, getInitDateRange } from "modules/Dashboard/helpers";
import useCurrencyList from "modules/Dashboard/hooks/useCurrencyList";
import { useTranslation } from "pay-kit";
import { ReactElement } from "react";

import styles from "./filters.module.scss";
import { fromLocaleDate, notLaterThanToday, toLocaleDate } from "utils/date";

const { dateFrom, dateTo } = getInitDateRange();

const Filters = () => {
	const { list, isLoading } = useCurrencyList();
	const { updateTransactionMetrics, checkedPS, isTransactionMetricsLoading } = useDashboardContext();
	const { t } = useTranslation();

	const currencyCodeOptions = list
		? list.map((wallet) => ({ label: wallet.code, value: wallet.code }))
		: [
				{
					label: "USD",
					value: "USD",
				},
		  ];

	const handleOnSubmit: FiltersFormStateType = (formState) => {
		const { display_in_currency, date_type, filters, currency_codes, ...restState } = formState;
		const payment_systems = Object.values(checkedPS)
			.filter((checkedState) => checkedState && checkedState.isChecked)
			.map((checkedState) => checkedState?.value);

		const date_from = fromLocaleDate(restState.dateRange.from);
		const date_to = fromLocaleDate(restState.dateRange.to);

		const isBoxChecked: IsBoxCheckedType = (element) => filters?.includes(element);

		const show_all_active_transaction_data = isBoxChecked(`show_all_active_transaction_data`) ? 1 : 0;
		const show_details = isBoxChecked(`show_details`) ? 1 : 0;
		const stockpiling_status = isBoxChecked(`stockpiling_status`) ? 2 : 1;

		const args = {
			date_from,
			date_to,
			display_in_currency,
			currency_codes,
			date_type,
			payment_systems,
			show_all_active_transaction_data,
			show_details,
			stockpiling_status,
		};

		updateTransactionMetrics(args);
	};

	const handleOnReset = () => {
		const args = getDashboardFiltersInitPrams();
		updateTransactionMetrics(args);
	};

	const SCHEMA = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.wallet}>
					{t("Currency")}
					{element}
				</div>
			),
			elements: [
				{
					name: "currency_codes",
					type: "MultiSelect",
					placeholder: t(`Choose`),
					options: currencyCodeOptions,
					isLoading: isLoading,
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.formattingByDate}>
					{t(`Group by date`)}
					{element}
				</div>
			),
			elements: [
				{
					type: "Switcher",
					name: "date_type",
					className: styles.switcher,
					options: [
						{ label: t(`Activations`), value: "activation_date" },
						{ label: t(`Creations`), value: "creation_date" },
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.dateRangeWrapper}>
					{t("Date")}
					{element}
				</div>
			),
			elements: [
				{
					name: "dateRange",
					type: "DateRangePicker",
					withTime: true,
					className: styles.dateRange,
					fromPlaceholder: t(`From`),
					toPlaceholder: t(`To`),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					customStyles: () => ({
						top: `unset`,
						right: `unset`,
						transform: `translate(-330px, -270px)`,
					}),
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => (
				<div className={styles.selectWallet}>
					{t("Display in")}
					{element}
				</div>
			),
			elements: [
				{
					name: "display_in_currency",
					type: "Select",
					options: currencyCodeOptions,
					placeholder: "",
					isLoading: isLoading,
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.checkBoxGroup}>{element}</div>,
			elements: [
				{
					name: "filters",
					type: "CheckboxGroup",
					label: "CheckboxGroup",
					className: styles.checkBoxGroup,
					options: [
						{ label: t(`Show details`), value: "show_details" },
						{ label: t("Total sum"), value: "show_all_active_transaction_data" },
						{ label: t(`With completed stockpiling`), value: "stockpiling_status" },
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.formControls}>{element}</div>,
			elements: [
				{
					name: "reset",
					type: "ResetButton",
					label: t("Reset"),
					variant: "secondary",
					className: styles.controlButton,
					onClick: handleOnReset,
				},
				{
					name: "submit",
					type: "SubmitButton",
					label: t("Apply"),
					className: styles.controlButton,
					isLoading: isTransactionMetricsLoading,
					onSubmit: handleOnSubmit,
				},
			],
		},
	];

	const initialState = {
		display_in_currency: "USD",
		date_type: "activation_date",
		filters: ["show_all_active_transaction_data"],
		dateRange: {
			from: toLocaleDate(dateFrom),
			to: toLocaleDate(dateTo),
		},
	};

	return (
		<div className={styles.filtersWrapper}>
			<div className={styles.filtersHeader}>{t("Filters")}</div>
			<PayKitForm.Builder schema={SCHEMA} initialState={initialState} />
		</div>
	);
};

export default Filters;

type FiltersType = "show_all_active_transaction_data" | "show_details" | "stockpiling_status";

type FiltersFormStateType = (formState: {
	readonly display_in_currency: string;
	readonly currency_codes?: readonly string[];
	readonly date_type: "activation_date" | "creation_date";
	readonly filters: readonly FiltersType[];
	readonly dateRange: {
		readonly from: string;
		readonly to: string;
	};
}) => void;

type IsBoxCheckedType = (element: FiltersType) => boolean | undefined;
