import { PayKitForm, ScrollBox } from "@paykassma/pay-kit";
import { WalletType } from "api/walletGroup";
import CustomWalletOption from "components/CustomWalletOption";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";
import { rejectSettlement } from "utils/filterSettlement";

import { CompletedListReportReqType } from "/api/reportsGroup";

import { ActionsContext } from "../../../../../ActionsProvider";
import AdditionalSettingsSection from "../../components/AdditionalSettingsSection";
import styles from "./TransactionsCompletedList.module.scss";
import useGetAllKindWallets from "../../../../hooks/getAllKindWallets";

const TransactionsCompletedList = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { getWalletsAPI, createTransactionsCompletedListReportAPI } = useContext(ActionsContext);

	const [currentWalletType, setWalletType] = useState<WalletType | null>(null);

	const { t } = useTranslation();
	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);
	const allKindWallets = useGetAllKindWallets();


	useEffect(() => {
		if (currentWalletType && hasWalletsAccess) {
			allKindWallets.load(currentWalletType);

		}
	}, [currentWalletType, hasWalletsAccess]);

	// wallet types
	const walletTypesOptions = [
		{ label: t("All") },
		...rejectSettlement(walletsContext.walletTypes).map(({ name, code }) => ({ label: name, value: code })),
	];

	// wallets (filtering by wallet type)
	const getWalletsOptions = (wallet_type: string) => [
		{ label: t("All") },
		...allKindWallets.list
			.filter((w) => (!wallet_type ? false : w.wallet_type === wallet_type))
			.map((w) => ({ label: `${w.identifier}`, value: w.hash_id, is_active: w.is_active, removed_at: w.removed_at })),
	];

	const FORM_SCHEMA = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "wallet_type",
					label: t("Wallet type"),
					type: "Select",
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: "direction",
					label: t("Direction"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ value: "outgoing", label: t("Outgoing") },
						{ value: "ingoing", label: t("Ingoing") },
					],
				},
				{
					name: "wallet_hash_id",
					label: t("Wallet"),
					type: "Select",
					isLoading: getWalletsAPI.isLoading,
					options: ({ wallet_type }: FormStateType) => getWalletsOptions(wallet_type as string),
					customOption: CustomWalletOption,
					existsIf: hasWalletsAccess,
					disabled: ({ wallet_type }: FormStateType) => !wallet_type,
				},
				{
					name: "type",
					label: t("Transaction kind"),
					type: "MultiSelect",
					placeholder: t("All"),
					options: [
						{ label: t("Confirmed"), value: "0" },
						{ label: t("Forced"), value: "2" },
						{ label: t("Debug"), value: "1" },
					],
				},
				{
					name: "stockpiling_date",
					label: t("Stockpiling end period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: ({ archiving_status }) => archiving_status !== "onlyArchived",
					validation: (value, { archiving_status, stockpiling_date }) =>
						archiving_status !== "onlyArchived" && !stockpiling_date ? t("Choose period") : undefined,
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -150px)`,
					})),
				},
				{
					name: "creation_type",
					label: t("Transaction type"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Auto"), value: "auto" }, { label: t("Manual"), value: "manual" }],
				},
				{
					name: "archiving_status",
					label: t("Transaction archiving status"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ label: t("Archived only"), value: "onlyArchived" },
						{ label: t("Not archived only"), value: "withoutArchived" },
					],
				},
				{
					name: "postback_status",
					label: t("Postback status"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ label: t("Sent"), value: 1 },
						{ label: t("Error sending postback"), value: 0 },
					],
				},
				{
					name: "archiving_date",
					label: t("Archiving period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: "DD.MM.YYYY",
					blockPredicate: notLaterThanToday,
					withTime: true,
					// disabled: ({ archiving_status }) => archiving_status === "withoutArchived", // TODO: разобраться со странной проблемой disabled в DateRangePicker
					isRequired: ({ archiving_status }: FormStateType) => archiving_status === "onlyArchived",
					existsIf: ({ archiving_status }: FormStateType) => archiving_status !== "withoutArchived",
					validation: (value: string, { archiving_status, archiving_date }: FormStateType) =>
						archiving_status === "onlyArchived" && !archiving_date ? t("Choose period") : undefined,
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -240px)`,
					})),
				},
				{ name: "label", label: t("Label"), type: "TextInput" },
				{ name: "utc_0", label: t("Timezone UTC+00:00"), type: "Toggler" },
				{ name: "exchanger_identifier", label: t("Counterparty"), type: "TextInput" },
				{
					name: "originality",
					label: t("Originality"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Normal"), value: true }, { label: t("Scam"), value: false }],
				},
			],
		},
		{
			type: "AdditionalSettingsSection",
			elements: [
				{
					type: "CheckboxGroup",
					name: "fields",
					options: [
						{
							value: "wallet_identifier",
							label: t("Wallet number"),
						},
						{
							value: "transaction_number",
							label: t("Transaction number"),
						},
						{
							value: "vendor_code",
							label: t("Wallet provider code"),
						},
						{
							value: "merchant_order_id",
							label: t("Order ID"),
						},
						{
							value: "exchanger_identifier",
							label: t("Counterparty"),
						},
						{
							value: "total_commission",
							label: t("Commission"),
						},
						{
							value: "stockpiling_date",
							label: t("Stockpiling end date"),
						},
						{
							value: "archiving_date",
							label: t("Archiving date"),
						},
						{
							value: "label",
							label: t("Label"),
						},
						{
							value: "type",
							label: t("Transaction kind"),
						},
						{
							value: "creation_type",
							label: t("Transaction type"),
						},
						{
							value: "postback_status",
							label: t("Postback status"),
						},
						{
							value: "stockpiling_id",
							label: t("Stockpiling ID"),
						},
					],
				},
			],
		},
		{
			type: "BottomSection",
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createTransactionsCompletedListReportAPI.isLoading,
					onSubmit: (formState: FormStateType) =>
						createTransactionsCompletedListReportAPI.create(prepareFormData(formState)),
				},
			],
		},
	];

	const customElements = {
		ScrollBox: (props) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<ScrollBox scrollDirection="vertical" className={styles.scrollBox}>
						{children}
					</ScrollBox>
				)}
			/>
		),
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		AdditionalSettingsSection,
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: "xlsx", fields: [], utc_0: true }}
				customElements={customElements}
				onStateChange={(prevForm, newForm) => {
					if (prevForm.wallet_type !== newForm.wallet_type) {
						setWalletType(newForm.wallet_type);
					}
				}}
			/>
		</div>
	);
};

export default TransactionsCompletedList;

type prepareFormDataType = (rawFormData: FormStateType) => CompletedListReportReqType;

type FormStateType = {
	readonly file_format: "xlsx" | "csv";
	readonly utc_0: boolean;
	readonly wallet_type?: string;
	readonly direction?: "outgoing" | "ingoing";
	readonly wallet_hash_id?: string;
	readonly type?: readonly ("0" | "1" | "2")[];
	readonly stockpiling_date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly creation_type?: "auto" | "manual";
	readonly archiving_status?: "onlyArchived" | "withoutArchived";
	readonly postback_status?: 0 | 1;
	readonly archiving_date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly label?: string;
	readonly exchanger_identifier?: string;
	readonly originality?: boolean;
	readonly fields: readonly (
		| "wallet_identifier"
		| "transaction_number"
		| "vendor_code"
		| "merchant_order_id"
		| "exchanger_identifier"
		| "total_commission"
		| "stockpiling_date"
		| "archiving_date"
		| "label"
		| "type"
		| "creation_type"
		| "postback_status"
		| "stockpiling_id"
	)[];
};

const prepareFormData: prepareFormDataType = (rawFormData) => ({
	filters: {
		wallet_type: rawFormData.wallet_type,
		wallet_hash_id: rawFormData.wallet_hash_id,
		direction: rawFormData.direction,
		creation_type: rawFormData.creation_type,
		type: rawFormData.type,
		label: rawFormData.label,
		exchanger_identifier: rawFormData.exchanger_identifier,
		archiving_status: rawFormData.archiving_status,
		archiving_date_from: fromLocaleDate(rawFormData.archiving_date?.from) || undefined,
		archiving_date_to: fromLocaleDate(rawFormData.archiving_date?.to) || undefined,
		stockpiling_date_from: fromLocaleDate(rawFormData.stockpiling_date?.from) || undefined,
		stockpiling_date_to: fromLocaleDate(rawFormData.stockpiling_date?.to) || undefined,
		originality: rawFormData.originality,
	},
	fields: rawFormData.fields,
	file_format: rawFormData.file_format,
	utc_0: rawFormData.utc_0,
});
