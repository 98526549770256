/* eslint-disable functional/no-class */
import { ElementType } from "@paykassma/pay-kit/lib/elements/PayKitForm/FormBuilder/types";

// Класс базовой ноды
class TreeNode<K> {
	readonly key: K | null;
	readonly parent: TreeNode<K> | null = null;
	readonly children: ReadonlyArray<TreeNode<K>> = [];
	// Глубина ноды, нужна для определения к какому родителю добавлять ноды
	readonly depth = 0;

	constructor(
		key: K | null,
		parent: TreeNode<K> | null = null,
		level = 0,
	) {
		this.key = key;
		this.parent = parent;
		this.children = [];
		this.depth = level;
	}
}

// Класс базового дерева
class Tree<K> {
	readonly root: TreeNode<K>;

	constructor(key: K | null) {
		this.root = new TreeNode(key);
	}

	// Обход в ширину
	breadthTraversal(): ReadonlyArray<K | null> {
		const result: ReadonlyArray<K | null> = [];

		const queue: ReadonlyArray<TreeNode<K>> = [];

		queue.push(this.root);

		while (queue.length > 0) {
			const node = queue.shift();
			if (node) {
				result.push(node?.key);
				queue.push(...node.children);
			}
		}

		return result;
	}
}

type Field = ElementType<any> & {
    readonly dependsOn?: ReadonlyArray<string>;
    readonly specific?: boolean;
    readonly order?: number;
}

export class FieldTree extends Tree<Field> {
	findByName(fieldName: string): TreeNode<Field> | null {
		const queue: ReadonlyArray<TreeNode<Field>> = [];

		queue.push(this.root);

		while (queue.length > 0) {
			const node = queue.shift();
			if (node?.key?.name === fieldName) {
				return node;
			}
			if (node) {
				queue.push(...node.children);
			}
		}

		return null;
	}

	insertFields(fields: ReadonlyArray<Field>, formState: any) {
		this.root = new TreeNode(null, null, 0);
		const addedFields: readonly string[] = [];

		let existingFields = fields.filter((field) => {
			if (field.existsIf) {
				return field.existsIf(formState);
			}
			return true;
		});
		const fieldNames = existingFields.map((field) => field.name);
		existingFields = existingFields.map((field) => {
			if (field.dependsOn) {
				field.dependsOn = field.dependsOn.filter((fieldName) => fieldNames.includes(fieldName));
				if (field.dependsOn.length == 0) {
					delete field.dependsOn;
				}
			}
			return field;
		});

		const sortByOrder = (array: ReadonlyArray<Field>): ReadonlyArray<Field> => {
			return array.sort((a, b) => {
				return (b.order || 0) - (a.order || 0);
			});
		};

		/**
         * Тречим последний добавленое специфичное поле,
         * тк нужно вставить UPI адреса как разделитель м-ду полями, специфичными для ПС и специфичными для группы ПС
        */
		let lastAddedSpecificNode: TreeNode<Field> | null = null;
		let lastAddedNode = this.root;

		// Добавим специфичные независимые поля
		sortByOrder(existingFields.filter((field) => field.specific && !field.dependsOn)).forEach((field) => {
			const node = new TreeNode(field, this.root, 1);
			this.root.children.push(node);
			addedFields.push(field.name || "");
			lastAddedSpecificNode = node;
			lastAddedNode = node;
		});

		// Получим все зависимые поля
		let dependentFields = new Set<Field>();

		const getArrayDependentFields = () => {
			return Array(...dependentFields.values());
		};

		existingFields.filter((field) => field.dependsOn).forEach((field) => {
			field.dependsOn?.forEach((dependent) => {
				const dependentFormField = fields.find((field) => field.name === dependent);
				if (dependentFormField) {
					dependentFields.add(dependentFormField);
				}
				dependentFields.add(field);
			});
		});
		// У UPI отдельная обработка
		let upiField = getArrayDependentFields().find((field) => field.name === "upi_addresses");
		if (upiField) {
			dependentFields.delete(upiField);
		}

		const dependentFieldsCopy = new Set(dependentFields);

		// Добавляем поля, которые не завият ни от кого, но у которых есть зависимые поля
		sortByOrder(getArrayDependentFields()).forEach((field) => {
			if (!field?.dependsOn) {
				dependentFieldsCopy.delete(field);
				const node = new TreeNode(field, this.root, 1);
				this.root.children.push(node);
				addedFields.push(field.name || "");
				lastAddedNode = node;
			}
		});

		// Добавляем оставшиеся зависимые поля
		dependentFields = new Set<Field>(dependentFieldsCopy);
		while (dependentFields.size > 0) {
			sortByOrder(getArrayDependentFields()
				.filter((field) => !!field)
			// Сначала нужно добавлять специфичные поля
				.sort((fieldA, fieldB) => Number(fieldB?.specific) - Number(fieldA?.specific)))
				.forEach((field) => {
					//Если все поля, от которых поле зависит, уже добавлены в дерево - добавляем поле в дерево
					if (field && field?.dependsOn && field.dependsOn?.every((dependent) => getArrayDependentFields().filter((f) => f.name === dependent).length == 0)) {
						const formFieldDependents = field?.dependsOn;
						const treeFields = formFieldDependents.map((treeField) => this.findByName(treeField)).filter((field) => field !== null);
						/*
                        Добавляем поле после самого глубокого родителя
                        Нужно чтобы поля не прыгали при изменении значений родителей
                     */
						const parent = treeFields.sort((a, b) => b.depth - a.depth)[0];
						if (parent) {
							const node = new TreeNode(field, parent, parent.depth + 1);
							parent.children.push(node);
							lastAddedNode = node;
							if (field.specific) {
								lastAddedSpecificNode = node;
							}
							addedFields.push(field.name || "");
							dependentFieldsCopy.delete(field);
						}
					}
				});
			dependentFields = new Set<Field>(dependentFieldsCopy);
		}

		// Добавляем оставшиеся независимые поля
		let remainingFields = existingFields.filter((field) => !addedFields.includes(field?.name || ""));

		// Добавляем UPI как разделитель м-ду полями специфичными для ПС и полями, специфичными для группы ПС
		upiField = remainingFields.find((field) => field.name === "upi_addresses");
		const nodeToAppendUpi = lastAddedSpecificNode || lastAddedNode;
		if (upiField && nodeToAppendUpi) {
			const specificParent = (nodeToAppendUpi as TreeNode<Field>).parent || this.root;
			const upiNode = new TreeNode(upiField, specificParent, specificParent.depth + 1);
			const specificChildren = specificParent.children;
			const lastAddedIndex = specificChildren.indexOf(nodeToAppendUpi);
			specificParent.children = [
				...specificChildren.slice(0, lastAddedIndex + 1),
				upiNode,
				...specificChildren.slice(lastAddedIndex + 1)
			];
		}
		remainingFields = sortByOrder(remainingFields.filter((field) => field !== upiField));

		remainingFields.forEach((field) => {
			const node = new TreeNode(field, lastAddedNode, lastAddedNode.depth + 1);
			lastAddedNode.children.push(node);
		});

		fields.forEach((field) => {
			if (field.existsIf && !field.existsIf(formState)) {
				const node = new TreeNode(field, lastAddedNode, lastAddedNode.depth + 1);
				lastAddedNode.children.push(node);
			}
		});
	}

	constructor(fields: ReadonlyArray<Field>, formState: any) {
		super(null);
		this.insertFields(fields, formState);
	}
}