// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomWalletOption__option-cCy{cursor:default;display:block;font-size:inherit;width:100%;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0);color:#21272a;padding:8px 12px;box-sizing:border-box}.CustomWalletOption__option-cCy:hover{cursor:pointer;background:#f1faff;color:#0072c3}.CustomWalletOption__notActiveOption-rVk{color:#dc3545}.CustomWalletOption__archived-bPs{color:gray}`, "",{"version":3,"sources":["webpack://./src/components/CustomWalletOption/CustomWalletOption.module.scss"],"names":[],"mappings":"AAAA,gCACI,cAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,gBAAA,CACA,yCAAA,CACA,aAAA,CACA,gBAAA,CACA,qBAAA,CAEA,sCACI,cAAA,CACA,kBAAA,CACA,aAAA,CAIR,yCACI,aAAA,CAGJ,kCACI,UAAA","sourcesContent":[".option {\n    cursor: default;\n    display: block;\n    font-size: inherit;\n    width: 100%;\n    user-select: none;\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    color: rgb(33, 39, 42);\n    padding: 8px 12px;\n    box-sizing: border-box;\n\n    &:hover {\n        cursor: pointer;\n        background: rgb(241, 250, 255);\n        color: rgb(0, 114, 195);\n    }\n}\n\n.notActiveOption {\n    color: #dc3545;\n}\n\n.archived {\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `CustomWalletOption__option-cCy`,
	"notActiveOption": `CustomWalletOption__notActiveOption-rVk`,
	"archived": `CustomWalletOption__archived-bPs`
};
export default ___CSS_LOADER_EXPORT___;
