import { useTranslation } from "pay-kit";
import { LoadQueryParams } from "api/walletGroup";
import Switcher from "components/Switcher";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { Button, DateRangePicker, ScrollBox, Select } from "pay-kit";
import { useContext, useEffect, useState } from "react";

import { FILTER_DEFAULT_STATE } from "../../index";
import styles from "./WalletsLoadFilter.module.scss";
import dayjs from "dayjs";

interface IFilterProps {
	readonly onSubmit: (value: LoadQueryParams) => void;
	readonly filterInitState: LoadQueryParams;
}

enum WALLET_STATUS_ENUM {
	ALL = "ALL",
	DISABLED = "DISABLED",
	ACTIVE = "ACTIVE",
	REMOVED = "REMOVED",
}

const WALLET_STATUS_MAPPING = {
	[WALLET_STATUS_ENUM.ALL]: { is_active: undefined, removed: undefined },
	[WALLET_STATUS_ENUM.DISABLED]: { is_active: false, removed: false },
	[WALLET_STATUS_ENUM.ACTIVE]: { is_active: true, removed: false },
	[WALLET_STATUS_ENUM.REMOVED]: { is_active: undefined, removed: true },
};

const Filter: React.FC<IFilterProps> = ({ onSubmit, filterInitState }) => {
	const { t } = useTranslation();
	const [filterState, setFilterState] = useState<LoadQueryParams>(filterInitState);
	const walletsAPI = useContext(WalletTypesContext);

	useEffect(() => {
		if (filterState.direction === "outgoing") {
			fieldChangeHandler("transaction_status", undefined);
		}
	}, [filterState.direction]);

	useEffect(() => {
		if (filterState.load_object === "payment_systems") {
			fieldChangeHandler("wallet_type", undefined);
		}
	}, [filterState.load_object])

	const fieldChangeHandler = (name: keyof LoadQueryParams, value: any) =>
		setFilterState((prevValue: LoadQueryParams) => ({ ...prevValue, [name]: value }));

	const pickerCustomStyles = () => ({
		top: `unset`,
		right: `unset`,
		transform: `translate(-330px, -270px)`,
	});

	return (
		<ScrollBox className={styles.filter} scrollDirection="vertical">
			<div className={styles.header}>
				{t("Load view setting")} <hr />
			</div>
			<Switcher
				className={styles.switcher}
				label={t("Direction")}
				value={filterState.direction}
				onSwitch={(v) => fieldChangeHandler("direction", v as number)}
				options={[
					{ value: "outgoing", label: t("Outgoing") },
					{ value: "ingoing", label: t("Ingoing") },
				]}
			/>

			{filterState.direction === "ingoing" && (
				<Select
					name="transaction-status"
					label={t("Transaction status")}
					value={filterState.transaction_status}
					onChange={(v) => fieldChangeHandler("transaction_status", v as number)}
					options={[
						{ value: undefined, label: t("All") },
						{ value: 1, label: t("Activated") },
						// { value: 0, label: "Неактивированные" },
						{ value: 0, label: t("Not activated") },
					]}
					noError
					data-test-id="transaction_status"
				/>
			)}

			<Switcher
				className={styles.switcher}
				label={t("Load object")}
				value={filterState.load_object}
				onSwitch={(v) => fieldChangeHandler("load_object", v as number)}
				options={[
					{ value: "wallets", label: t("Wallets") },
					// { value: "payment_systems", label: "ПС" },
					{ value: "payment_systems", label: t("Payment systems") },
				]}
				data-test-id="load_object"
			/>

			{filterState.load_object === "wallets" && (
				<Select
					name="wallet_type"
					label={t("Wallet type")}
					value={filterState.wallet_type}
					onChange={(v) => fieldChangeHandler("wallet_type", v as number)}
					options={[
						{ value: undefined, label: t("All") },
						...walletsAPI.walletTypes.map((w) => ({ label: w.name, value: w.code })),
					]}
					isLoading={walletsAPI.isLoading}
					noError
					data-test-id="wallet_type"
				/>
			)}

			<Switcher
				className={styles.switcher}
				label={t("Load type")}
				value={filterState.load_type}
				onSwitch={(v) => fieldChangeHandler("load_type", v as number)}
				options={[
					{ value: "transactions_count", label: t("Count") },
					{ value: "transactions_sum", label: t("Amount") },
				]}
				data-test-id="load_type"
			/>

			<Select
				name="wallet_status"
				label={t("Wallet status")}
				value={Object.keys(WALLET_STATUS_MAPPING).find(
					(key) =>
						WALLET_STATUS_MAPPING[key as keyof typeof WALLET_STATUS_ENUM].is_active === filterState.is_active &&
						WALLET_STATUS_MAPPING[key as keyof typeof WALLET_STATUS_ENUM].removed === filterState.removed
				)}
				onChange={(key) =>
					setFilterState((prevValue) => ({
						...prevValue,
						...WALLET_STATUS_MAPPING[key as keyof typeof WALLET_STATUS_ENUM],
					}))
				}
				options={[
					{ value: WALLET_STATUS_ENUM.ALL, label: t("All") },
					{ value: WALLET_STATUS_ENUM.DISABLED, label: t("Disabled wallets") },
					{ value: WALLET_STATUS_ENUM.ACTIVE, label: t("My wallets") },
					{ value: WALLET_STATUS_ENUM.REMOVED, label: t("Deleted wallets") },
				]}
				noError
				data-test-id="status"
			/>

			<label className={styles.dateRangeField}>
				<div className={styles.label}>{t("Date of transaction creation")}</div>
				<DateRangePicker
					value={
						filterState.create_date_from && filterState.create_date_to
							? { from: filterState.create_date_from, to: filterState.create_date_to }
							: undefined
					}
					onChange={(date) =>
						setFilterState((prevValue) => ({ ...prevValue, create_date_from: date.from, create_date_to: date.to }))
					}
					data-test-id="create_date"
					dateFormat="DD.MM.YYYY"
					withTime
					customStyles={pickerCustomStyles}
					// TODO: restrictions
				/>
			</label>

			{filterState.transaction_status === 1 && (
				<label className={styles.dateRangeField}>
					<div className={styles.label}>{t("Date transaction activation")}</div>
					<DateRangePicker
						value={
							filterState.date_from && filterState.date_to
								? { from: filterState.date_from, to: filterState.date_to }
								: undefined
						}
						onChange={(date) =>
							setFilterState((prevValue) => ({ ...prevValue, date_from: date.from, date_to: date.to }))
						}
						data-test-id="date"
						dateFormat="YYYY-MM-DD"
						withTime
						customStyles={pickerCustomStyles}
						// TODO: restrictions
					/>
				</label>
			)}

			<div className={styles.actions}>
				<Button
					variant="secondary"
					onClick={() => {
						setFilterState(FILTER_DEFAULT_STATE);
						onSubmit(FILTER_DEFAULT_STATE);
					}}
					data-test-id=""
				>
					{t("Reset")}{" "}
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						onSubmit(filterState);
					}}
					data-test-id=""
				>
					{t("Apply")}{" "}
				</Button>
			</div>
		</ScrollBox>
	);
};

export default Filter;
