import { Table } from "@paykassma/pay-kit";
import { IPossibleTransactionsFilterForm } from "api/transactionGroup";
import React, { useEffect, useState } from "react";
import useURLState from "utils/hooks/useURLState";
import { Mutable } from 'utils/types';

import ReloadButton from "../../Postbacks/components/ReloadButton";
import { possibleTransactionsTableColumns, possibleTransactionsTableSubRow } from "../components/columns";
import usePossibleTransactionsListAPI from "../hooks/usePossibleTransactionsListAPI";
import ActionsProvider from './ActionsProvider';
import DetailedView from "./DetailedView";
import Filter from "./Filter";
import styles from "./PossibleTransactions.module.scss";
import { FilterErrors, isFilterInvalid, validateFilters } from "../CurrentTransactions/Filter/utils";

const PossibleTransactions: React.FC = () => {
	const [page, setCurrentPage] = useURLState<number>("page", 1);
	const [limit, setLimit] = useURLState<number>("limit", 10);
	const [filter, setFilter] = useState<IPossibleTransactionsFilterForm>({});
		// Валидация фильтров
		const [filterErrors, setFilterErrors] = useState<FilterErrors>({
			transaction_id: null,
		});
	const [detailedTransactionViewId, setDetailedTransactionViewId] = useState<number | string | null>(null);

	const { transactions, isLoading, error, paginationInfo, getTransactionsList } = usePossibleTransactionsListAPI();

	const loadTransactions = () => getTransactionsList({ limit, page, ...filter });

	useEffect(loadTransactions, [limit, page, filter]);

	const detailedTransactionViewData = detailedTransactionViewId
		? transactions.find((transaction) => transaction.primary_id === detailedTransactionViewId)
		: undefined;

	return (
		<ActionsProvider onSuccess={loadTransactions} transactions={transactions}>
			<DetailedView
				transactionData={detailedTransactionViewData}
				onClose={() => setDetailedTransactionViewId(null)}
			/>
			<Filter
				value={filter}
				onApply={(value: Mutable<IPossibleTransactionsFilterForm>) => {
					const errors = validateFilters(value as FilterErrors);

					setFilterErrors(errors);

					if (isFilterInvalid(errors)) {
						return;
					}

					setCurrentPage(1);
					setFilter(value);
				}}
				errors={filterErrors}
				setErrors={setFilterErrors}
			/>
			<Table
				className={styles.PossibleTransactionsTable}
				columns={possibleTransactionsTableColumns}
				data={transactions}
				rowKey="primary_id"
				message={error ? { text: `${error}`, type: "error" } : undefined}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				onRowClick={setDetailedTransactionViewId}
				subRowRender={possibleTransactionsTableSubRow}
				stickyHead
				paginator={
					paginationInfo
						? {
							currentPage: paginationInfo?.current_page || 1,
							setCurrentPage: (page) => setCurrentPage(page as number),
							limit,
							setLimit,
							lastAvailablePage: paginationInfo?.last_available_page || 1,
							prefixElement: (
								<ReloadButton
									data-test-id="SFAzTrprG5NJEzs8duRHMууу"
									isLoading={isLoading}
									onClick={loadTransactions}
								/>
							),
							className: styles.paginator,
							bottomPaginatorProps: {
								className: styles.bottomPaginator,
							},
						}
						: undefined
				}
			/>
		</ActionsProvider>
	);
};

export default PossibleTransactions;
