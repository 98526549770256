import styles from "./CustomWalletOption.module.scss";

const CustomWalletOption = ({ innerProps, children, value, data }) => {
	const classnames = [styles.option];
	let hoverTip = undefined;

	if (value && data.removed_at !== null) {
		classnames.push(styles.archived);
		hoverTip = "archived";
	} else if (data.is_active === false) {
		classnames.push(styles.notActiveOption);
		hoverTip = "not active";
	}

	return (
		<div {...innerProps} className={classnames.join(` `)} title={hoverTip}>
			{children}
		</div>
	);
};

export default CustomWalletOption;
