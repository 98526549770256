import API from "api";
import { IPossibleTransaction } from "api/transactionGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

type ReturnType = {
	readonly load: (args: { readonly wallet_type: string; readonly transaction_id: string }) => void;
	readonly clear: () => void;
	readonly list: readonly IPossibleTransaction[];
	readonly isLoading: boolean;
};

type HookType = (props: { readonly onSuccess: (list: readonly IPossibleTransaction[]) => void }) => ReturnType;

const useGetPossibleTransactions: HookType = ({ onSuccess }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<readonly IPossibleTransaction[]>([]);

	const clear = () => setList([]);

	const load = ({ wallet_type, transaction_id }: { readonly wallet_type: string; readonly transaction_id: string }) => {
		clear();
		setIsLoading(true);

		API.transaction
			.getPossibleList({ wallet_type, transaction_id, status: 0 })
			.then((res: any) => {
				if (res.status === "success") {
					setList(res?.data.transactions?.data);
					onSuccess(res?.data.transactions?.data);
					return res;
				} else {
					// throw new Error("Unexpected response in useGetPossibleTransactions");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	return { list, isLoading, load, clear };
};

export default useGetPossibleTransactions;
