import { PayKitForm } from "@paykassma/pay-kit";
import { WalletType } from "api/walletGroup";
import CustomWalletOption from "components/CustomWalletOption";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";
import { rejectSettlement } from "utils/filterSettlement";

import { CompletedSumReportReqType } from "/api/reportsGroup";

import { ActionsContext } from "../../../../../ActionsProvider";
import styles from "./CompletedSumReport.module.scss";
import useGetAllKindWallets from "../../../../hooks/getAllKindWallets";

const CompletedSumReport = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { getWalletsAPI, createTransactionsCompletedSumReportAPI } = useContext(ActionsContext);

	const [currentWalletType, setWalletType] = useState<WalletType | null>(null);
	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);
	const allKindWallets = useGetAllKindWallets();

	const { t } = useTranslation();

	useEffect(() => {
		if (currentWalletType && hasWalletsAccess) {
			allKindWallets.load(currentWalletType);
		}
	}, [currentWalletType, hasWalletsAccess]);

	// wallet types
	const walletTypesOptions = [
		{ label: t("All") },
		...rejectSettlement(walletsContext.walletTypes).map(({ name, code }) => ({ label: name, value: code })),
	];

	// wallets (filtering by wallet type)
	const getWalletsOptions = (wallet_type: string) => [
		{ label: t("All") },
		...allKindWallets.list
			.filter((w) => (!wallet_type ? false : w.wallet_type === wallet_type))
			.map((w) => ({ label: `${w.identifier}`, value: w.hash_id, is_active: w.is_active, removed_at: w.removed_at })),
	];

	// currencies
	const getCurrenciesOfCurrentWalletType = (wallet_type: undefined | string) =>
		walletsContext.walletTypes
			.find(({ code }) => wallet_type === code)
			?.supported_currencies.map((c) => ({ label: c, value: c })) || [];

	const allCurrencies = walletsContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);
	const allCurrenciesOptions = allCurrencies.map((c) => ({ label: c, value: c }));

	const FORM_SCHEMA = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "wallet_type",
					label: t("Wallet type"),
					type: "Select",
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: "direction",
					label: t("Direction"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ value: "outgoing", label: t("Outgoing") },
						{ value: "ingoing", label: t("Ingoing") },
					],
				},
				{
					name: "wallet_hash_id",
					label: t("Wallet"),
					type: "Select",
					isLoading: getWalletsAPI.isLoading,
					options: ({ wallet_type }: FormStateType) => getWalletsOptions(wallet_type as string),
					customOption: CustomWalletOption,
					existsIf: hasWalletsAccess,
					disabled: ({ wallet_type }: FormStateType) => !wallet_type,
				},
				{
					name: "type",
					type: "MultiSelect",
					label: t("Transaction kind"),
					placeholder: t("All"),
					options: [
						{ label: t("Confirmed"), value: 0 },
						{ label: t("Forced"), value: 2 },
						{ label: t("Debug"), value: 1 },
					],
				},
				{
					name: "report_currency_code",
					label: t("Currency"),
					placeholder: "",
					type: "Select",
					options: ({ wallet_type }: FormStateType) =>
						wallet_type ? getCurrenciesOfCurrentWalletType(wallet_type) : allCurrenciesOptions,
					isLoading: walletsContext.isLoading,
					isRequired: true,
					validation: (value: string, { report_currency_code }: FormStateType) =>
						!report_currency_code ? t("Choose currency") : undefined,
				},
				{
					name: "creation_type",
					label: t("Transaction type"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Auto"), value: "auto" }, { label: t("Manual"), value: "manual" }],
				},
				{
					name: "stockpiling_date",
					label: t("Period"),
					type: "DateRangePicker",
					isRequired: true,
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					validation: (value: string, { stockpiling_date }: FormStateType) =>
						!stockpiling_date ? t("Choose period") : undefined,
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -150px)`,
					})),
				},
				{
					name: "postback_status",
					label: t("Postback status"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ label: t("Sent"), value: 1 },
						{ label: t("Error sending postback"), value: 0 },
					],
				},
				{ name: "detail_by_day", label: t("Detail by day"), type: "Toggler" },
				{ name: "label", label: t("Label"), type: "TextInput" },
				{ name: "utc_0", label: t("Timezone UTC+00:00"), type: "Toggler" },
				{ name: "exchanger_identifier", label: t("Counterparty"), type: "TextInput" },
				{
					name: "originality",
					label: t("Originality"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Normal"), value: true }, { label: t("Scam"), value: false }],
				},
			],
		},
		{
			type: "AdditionalSettingsSection",
			elements: [{ name: "commission", label: t("Commission"), type: "Checkbox" }],
		},
		{
			type: "BottomSection",
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createTransactionsCompletedSumReportAPI.isLoading,
					onSubmit: (formState: FormStateType) =>
						createTransactionsCompletedSumReportAPI.create(
							prepareFormData(formState),
							formState.detail_by_day === true
						),
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		AdditionalSettingsSection: (props) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.additionalSettings}>
						<h3>{t("Additionaly show")}</h3>
						{children}
					</div>
				)}
			/>
		),
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: "xlsx", utc_0: true, detail_by_day: false }}
				customElements={customElements}
				onStateChange={(prevForm, newForm) => {
					if (prevForm.wallet_type !== newForm.wallet_type) {
						setWalletType(newForm.wallet_type);
					}
				}}
			/>
		</div>
	);
};

export default CompletedSumReport;

type FormStateType = {
	readonly wallet_type?: string; // {t("Wallet type")}	readonly direction?: "outgoing" | "ingoing"; // Направление
	readonly wallet_hash_id?: string; // {t("Wallet")}(shows only when wallet type choosen)
	readonly type?: readonly ("0" | "1" | "2")[]; // Вид транзакций
	readonly postback_status?: 0 | 1;
	readonly report_currency_code?: string; // {t("Currency")}*
	readonly creation_type?: "auto" | "manual"; // Тип транзакции
	readonly stockpiling_date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly label?: string; // {t("Label")}	readonly detail_by_day?: boolean; // {t("Detail by day")}(когда true – запрос летит в sum-detail иначе в sum)
	readonly exchanger_identifier?: string; // {t("Counterparty")}	readonly utc_0?: boolean; // {t("Timezone")}UTC+00:00
	readonly commission?: boolean; // галочка комиссии
	readonly file_format: "xlsx" | "csv"; // Формат отчета csv | xlsx
	readonly originality?: boolean; // оригинальность
};

type prepareFormDataType = (rawFormData: FormStateType) => CompletedSumReportReqType;

const prepareFormData: prepareFormDataType = (rawFormData) => ({
	filters: {
		wallet_type: rawFormData.wallet_type,
		wallet_hash_id: rawFormData.wallet_hash_id,
		direction: rawFormData.direction,
		type: rawFormData.type,
		creation_type: rawFormData.creation_type,
		label: rawFormData.label,
		exchanger_identifier: rawFormData.exchanger_identifier,
		postback_status: rawFormData.postback_status,
		stockpiling_date_from: fromLocaleDate(rawFormData.stockpiling_date?.from),
		stockpiling_date_to: fromLocaleDate(rawFormData.stockpiling_date?.to),
		originality: rawFormData.originality,
	},
	fields: rawFormData.commission === true ? ["total_commission", "deposit_commission", "withdrawal_commission"] : [],
	file_format: rawFormData.file_format,
	utc_0: rawFormData.utc_0 || false,
	report_currency_code: rawFormData.report_currency_code || "",
	report_currency_id: 0,
	detail_by_day: rawFormData.detail_by_day,
});
