/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import Api from "api";
import { CountryType } from "api/countryGroup";
import { DeleteProxyType, GetProxiesFilter, ProxiesType, UpdateProxyType } from "api/proxiesGroup";
import { WalletType } from "api/walletGroup";
import RightModal from "components/Modals/RightModal";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import { stringifyErrorMessage } from "helpers/stringifyErrorMessage";
import DeleteProxies from "modules/Proxies/components/DeleteProxiesModal";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";
import UpdateProxy from "modules/Proxies/components/UpdateProxyModal";
import WalletsAmountSideBar from "modules/Proxies/components/WalletsAmountSideBar";
import { proxyCheckingStates, reduce, setTimeoutPromise } from "modules/Proxies/contexts/helpers";
import { useTranslation } from "pay-kit";
import { Modal } from "pay-kit";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useReducer, useState } from "react";
import { errorsMap } from "utils/enums";

import styles from "./proxyContext.module.scss";

const ProxyContext = createContext({} as ProxyContextType);

const ProxyContextProvider = ({ children }: { readonly children: JSX.Element }) => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [proxies, setProxies] = useState<readonly ProxiesType[]>([]);
	const [countryList, setCountryList] = useState<readonly CountryType[] | null>([]);
	const [isGetCheckedLoading, setIsGetCheckedLoading] = useState(false);
	const [isCheckProxyLoading, setIsCheckProxyLoading] = useState(false);
	const [IDsForDelete, setIDsForDelete] = useState<IDsForDeleteType>({} as IDsForDeleteType);
	const [isDeleteProxiesModal, setIsDeleteProxiesModal] = useState(false);
	const [proxyItem, setProxyItem] = useState<ProxyCommonType | null>(null);
	const [walletsList, setWalletsList] = useState<readonly WalletType[] | null>([]);
	const [isWalletListLoading, setIsWalletListLoading] = useState<boolean>(false);
	const [proxyCheckingState, dispatch] = useReducer(reduce, {} as ReduceStateType, undefined);
	const [isUpdateProxyModal, setIsUpdateProxyModal] = useState(false);
	const [proxyID, setProxyID] = useState<string | undefined>(undefined);
	const [walletsAmountSideBarState, setWalletsAmountSideBarState] = useState<WalletsAmountSideBarDataType>({
		show: false,
		walletsData: undefined,
	});
	const [isProxyLoading, setIsProxyLoading] = useState(false);
	const [isProxyItemLoading, setIsProxyItemLoading] = useState(false);
	const hasAccessToFetchWallets = useHasAccessHook({ rule: Roles.WALLET_LIST });
	const { t } = useTranslation();

	const getProxies = (filter: GetProxiesFilter) => {
		setIsProxyLoading(true);
		setError(null);

		Api.proxies
			.getProxies(filter)
			.then((res) => {
				if (res.status === "ok") {
					setProxies(res.proxies);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsProxyLoading(false);
				setIsUpdateProxyModal(false);
			});
	};

	useEffect(getProxies, []);

	useEffect(() => {
		setIsLoading(true);
		setError(null);

		Api.country
			.getList()
			.then((res) => {
				if (res.status === "ok") {
					setCountryList(res.countries);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const getWallets = (params: boolean) => {
		setIsWalletListLoading(true);
		setError(null);
		setWalletsList([]);

		Api.wallet
			.getWallets(params)
			.then((res) => {
				if (res.status === "ok") {
					setWalletsList(res.wallets);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsWalletListLoading(false);
			});
	};

	const getChecked = () => {
		setIsGetCheckedLoading(true);
		setError(null);

		Api.proxies
			.getChecked()
			.then((res) => {
				if (res.status === "ok" && res.checked) {
					setProxies(res.proxies);
					setIsGetCheckedLoading(false);
					window.pushAlert({
						description: t(`Proxy check completed`),
						type: "success",
					});
				}

				if (!res.status || res.status === "fail" || !res.checked || !res) {
					setIsGetCheckedLoading(true);
					setTimeoutPromise({ milliseconds: 10000, delayedMethod: getChecked });

					window.pushAlert({
						description: t(`Proxy check is in progress`),
						type: "warning",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
				setIsGetCheckedLoading(false);
			});
	};

	const initCheckAll = () => {
		setIsGetCheckedLoading(true);
		setError(null);

		Api.proxies
			.initCheckAll()
			.then((res) => {
				if (res.status === "ok") {
					getChecked();
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => dispatch({ type: proxyCheckingStates.RESET }));
	};

	const checkProxy = (uuid: string) => {
		setIsCheckProxyLoading(true);
		setError(null);
		dispatch({ type: proxyCheckingStates.PENDING, payload: { uuid } });

		Api.proxies
			.checkProxy(uuid)
			.then((res) => {
				if (res.status === "ok") {
					dispatch({ type: proxyCheckingStates.SUCCEEDED, payload: { uuid } });
				} else {
					dispatch({ type: proxyCheckingStates.FAILED, payload: { uuid } });

					window.pushAlert({
						type: "error",
						description: res.message,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsCheckProxyLoading(false);
				dispatch({ type: proxyCheckingStates.FINALLY, payload: { uuid } });
			});
	};

	const deleteProxy = (params: DeleteProxyType) => {
		setIsLoading(true);
		setError(null);

		Api.proxies
			.deleteProxy(params)
			.then((res) => {
				if (res.status === "ok") {
					window.pushAlert({
						type: "success",
						description: t(`Proxy removed`),
					});

					setProxies((prevState) => {
						return prevState.filter((elem) => !params.proxies.includes(elem.uuid));
					});
				}

				if (res.status === "fail") {
					window.pushAlert({
						type: "error",
						description: typeof res.message === "object" ? JSON.stringify(res.message) : res.message,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const loadProxyItem = (uuid: string) => {
		setIsLoading(true);
		setError(null);
		setIsProxyItemLoading(true);
		setProxyItem(null);

		Api.proxies
			.loadProxyItem(uuid)
			.then((res) => {
				if (res.status === "ok") {
					setProxyItem(res.proxy);
				} else {
					// throw new Error("Unexpected response in loadProxyItem");
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
				setIsProxyItemLoading(false);
			});
	};

	const updateProxy = (params: Partial<ProxyCommonType>, uuid: string) => {
		setIsProxyLoading(true);
		Api.proxies
			.updateProxy(params as UpdateProxyType, uuid)
			.then((res) => {
				if (res.status === "ok") {
					getProxies({});

					window.pushAlert({
						description: `Successfully created!`,
						type: "success",
					});
				} else {
					window.pushAlert({
						description: stringifyErrorMessage(res.message),
						type: "error",
					});

					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
				setIsProxyLoading(false);
			});
	};

	useEffect(() => {
		getWallets(true);
	}, []);

	const contextValue = {
		getProxies,
		loadProxyItem,
		updateProxy,
		deleteProxy,
		checkProxy,
		getWallets,
		initCheckAll,
		walletsList,
		countryList,
		proxyCheckingState,
		proxyItem,
		setProxies,
		proxies,
		setProxyID,
		proxyID,
		setWalletsAmountSideBarState,
		walletsAmountSideBarState,
		isProxyItemLoading,
		setIsProxyLoading,
		isProxyLoading,
		setIsUpdateProxyModal,
		setIsDeleteProxiesModal,
		setIDsForDelete,
		isWalletListLoading,
		isCheckProxyLoading,
		isGetCheckedLoading,
		isLoading,
	};

	return (
		<ProxyContext.Provider value={contextValue}>
			{children}

			<Modal
				title={t("Deleting the proxy")}
				onClose={() => setIsDeleteProxiesModal(false)}
				isOpen={isDeleteProxiesModal}
			>
				<DeleteProxies IDsForDelete={IDsForDelete} setIsDeleteProxiesModal={setIsDeleteProxiesModal} />
			</Modal>

			<Modal
				className={styles.UpdateProxyModalWrapper}
				title={t("Updating the proxy")}
				onClose={() => setIsUpdateProxyModal(false)}
				isOpen={isUpdateProxyModal}
			>
				<UpdateProxy />
			</Modal>

			<RightModal
				bodyClassName={styles.walletsAmountWrapper}
				heading={t(`Wallets`)}
				isVisible={walletsAmountSideBarState.show}
				// onClose={() => setWalletsAmountSideBarState((prevState) => ({ ...prevState, show: false }))}
				onClose={() => setWalletsAmountSideBarState({ show: false })}
				onShow={() => null}
			>
				<WalletsAmountSideBar />
			</RightModal>
		</ProxyContext.Provider>
	);
};

export default ProxyContextProvider;

export const useProxyContext = () => useContext(ProxyContext);

export type ProxyContextType = {
	readonly getProxies: (filter: GetProxiesFilter) => void;
	readonly loadProxyItem: (uuid: string) => void;
	readonly updateProxy: (params: Partial<ProxyCommonType>, uuid: string) => void;
	readonly deleteProxy: (proxies: DeleteProxyType) => void;
	readonly checkProxy: (uuid: string) => void;
	readonly getWallets: (params: boolean) => void;
	readonly initCheckAll: () => void;
	readonly walletsList: readonly WalletType[] | null;
	readonly countryList: readonly CountryType[] | null;
	readonly proxyCheckingState: ReduceStateType | undefined;
	readonly proxyItem: ProxyCommonType | null;
	readonly setProxies: Dispatch<SetStateAction<readonly ProxiesType[]>>;
	readonly proxies: readonly ProxiesType[];
	readonly setProxyID: Dispatch<SetStateAction<string | undefined>>;
	readonly proxyID?: string;
	readonly setWalletsAmountSideBarState: Dispatch<SetStateAction<WalletsAmountSideBarDataType>>;
	readonly walletsAmountSideBarState: WalletsAmountSideBarDataType;
	readonly isProxyItemLoading: boolean;
	readonly setIsProxyLoading: Dispatch<SetStateAction<boolean>>;
	readonly isProxyLoading: boolean;
	readonly setIsUpdateProxyModal: Dispatch<SetStateAction<boolean>>;
	readonly setIsDeleteProxiesModal: Dispatch<SetStateAction<boolean>>;
	readonly setIDsForDelete: Dispatch<SetStateAction<IDsForDeleteType>>;
	readonly isWalletListLoading: boolean;
	readonly isCheckProxyLoading: boolean;
	readonly isGetCheckedLoading: boolean;
	readonly isLoading: boolean;
};

export type WalletsAmountSideBarDataType = {
	readonly show: boolean;
	readonly walletsData?: {
		readonly amount: number;
		readonly ip: string;
		readonly IDs: readonly string[];
		readonly status: 0 | 1;
	};
};

export type IDsForDeleteType = DeleteProxyType & { readonly isIPlist?: boolean };

export type ReduceStateType = {
	readonly [key: string]: {
		readonly workingStatus?: "WORKING" | "NOT_WORKING";
		readonly isLoading?: boolean;
	};
};
