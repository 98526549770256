/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import API from "api";
import { StandardResponse } from "api/types";
import { WalletItem } from "api/walletGroup";
import { Requisite } from "api/walletTypesGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import ChangeWalletModal from "modules/Wallets/components/modals/ChangeWalletModal";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { WalletTypes } from "utils/enums";
import { screenPassword } from "utils/screening";
import { ModalProps } from "utils/types";

type UpdateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly actions: {
		readonly updateWallet: (form: any) => Promise<StandardResponse<void>>;
	};
	readonly walletId: string;
};

export const EditWalletModal = ({ closeModal, isOpen, actions, walletId }: UpdateWalletModalProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(true);
	const [created_at, setCreatedAt] = useState<string | undefined>(undefined);

	const [initForm, setInitForm] = useState<Partial<any>>({});

	const [respErrors, setErrors] = useState<any | null>(null);

	const [requisites, setRequisites] = useState<readonly Requisite[]>([]);

	const clearExternalErrors = () => {
		setErrors(null);
	};

	useEffect(() => {
		if (!isOpen) {
			clearExternalErrors();
		}
	}, []);

	const fetchRequisites = (wallet_type: string) => {
		API.walletType.getManualPSRequisites(wallet_type).then((resp) => {
			if (resp.status === "success") {
				setRequisites(resp.data.items);
			}
		});
	};

	const { walletTypes } = useContext(WalletTypesContext);

	useEffect(() => {
		API.wallet
			.getItem(walletId)
			.then((res: any) => {
				if (res?.status === "ok") {
					const data = res?.wallet;

					for (const key of Object.keys(data)) {
						if (data[key] === null) {
							delete data[key];
						}
					}
					const wallet_type = data.type;
					const isManual = walletTypes?.find((wt: any) => wt.code === wallet_type)?.payment_type === `MANUAL`;
					if (isManual) {
						fetchRequisites(wallet_type);
					}
					const currency_code = data.currency.code;
					const proxies = data.proxies.map(({ uuid }: any) => ({ uuid }));
					const cookie: string | undefined = data.cookie ? JSON.stringify(data.cookie) : undefined;

					if (data?.wallet_settings?.codephrases && typeof data?.wallet_settings?.codephrases === "string") {
						try {
							const parsedCodephrases = JSON.parse(data.wallet_settings.codephrases);
							if (Array.isArray(parsedCodephrases)) {
								data.wallet_settings.codephrases = parsedCodephrases;
							} else {
								throw new Error("Unexpected codephrases format");
							}
						} catch (e) {
							console.error(e);
						}
					}

					if (data.password) {
						data.password = screenPassword(data.password);
					}

					if (data.email_password) {
						data.email_password = screenPassword(data.email_password);
					}

					if (data.upi_addresses) {
						data.upi_addresses = data.upi_addresses.map((upi_address: string) => ({ upi_address }));
					}
					
					if (data.email_password || data.email_username) {
						data.email = {
							email_password: data.email_password,
							email_username: data.email_username,
						}
					}

					if (data.type === WalletTypes.PHONE_PE && data.wallet_settings.mnc) {
						data.mobileData = {...data.wallet_settings};
					}

					const initForm = {
						...data.wallet_settings,
						...data,
						currency_code,
						night_parser: false,
						site_parser: true,
						wallet_type,
						proxies,
						cookie,
					};

					setInitForm(initForm);

					if (data.created_at) {
						setCreatedAt(data.created_at);
					}

					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				window.pushAlert({ description: t("Error while wallet data fetching"), type: "error" });
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const onSuccess = () => {
		window.pushAlert({
			title: t("Success"),
			description: t("Wallet {{walletId}} changed", { walletId }),
			type: "success",
		});
	};

	const onSubmit = (form: Partial<WalletItem>) => {
		// if (form.account_number === "") {
		// 	form.account_number = null;
		// }

		let upi_addresses = (form.upi_addresses || []).map(({ upi_address }: Partial<WalletItem>) => upi_address);
		if (upi_addresses.length === 0) {
			upi_addresses = undefined;
		}
		return actions.updateWallet({ ...form, upi_addresses }).then((resp) => {
			if (resp.status === "error") {
				const errors = {...resp.errors};

				if (Object.hasOwn(errors, "email_username")) {
					errors["email.email_username"] = errors.email_username;
					delete errors.email_username;
				}
				setErrors(errors);
			}

			return resp;
		});
	};

	return (
		<ChangeWalletModal
			isCreating={false}
			created_at={created_at}
			loading={loading}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			initForm={initForm}
			closeModal={closeModal}
			actions={actions}
			externalErrors={respErrors}
			clearExternalErrors={clearExternalErrors}
			fetchRequisites={fetchRequisites}
			requisites={requisites}
			adminPanelType="instance"
		/>
	);
};

export default EditWalletModal;
