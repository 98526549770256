import { PayKitForm, ScrollBox } from "@paykassma/pay-kit";
import { WalletType } from "api/walletGroup";
import CustomWalletOption from "components/CustomWalletOption";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useMemo, useState } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";
import { rejectSettlement } from "utils/filterSettlement";

import { ListReportReqData } from "/api/reportsGroup";

import { ActionsContext } from "../../../../../ActionsProvider";
import AdditionalSettingsSection from "../../components/AdditionalSettingsSection";
import styles from "./TransactionsList.module.scss";
import useGetAllKindWallets from "../../../../hooks/getAllKindWallets";

const TransactionsList = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { getWalletsAPI, createTransactionsListReportAPI } = useContext(ActionsContext);
	const allKindWallets = useGetAllKindWallets();

	const [currentWalletType, setWalletType] = useState<WalletType | null>(null);
	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);
	const hasProviderAccess = hasRole(Roles.WALLET_PROVIDER_CODE);

	const { t } = useTranslation();

	useEffect(() => {
		if (currentWalletType && hasWalletsAccess) {
			allKindWallets.load(currentWalletType);
		}
	}, [currentWalletType, hasWalletsAccess]);

	// wallet types
	const walletTypesOptions = [
		{ label: t("All") },
		...rejectSettlement(walletsContext.walletTypes).map(({ name, code }) => ({ label: name, value: code })),
	];

	const getWalletsOptions = (wallet_type: string) => [
		{ label: t("All") },
		...allKindWallets.list
			.filter((w) => (!wallet_type ? false : w.wallet_type === wallet_type))
			.map((w) => ({ label: `${w.identifier}`, value: w.hash_id, is_active: w.is_active, removed_at: w.removed_at })),
	];

	const availableFields = useMemo(() => {
		let fields = [
			{ label: t("Wallet number"), value: "wallet_identifier" },
			{ label: t("Transaction number"), value: "transaction_number" },
		];

		if (hasProviderAccess) {
			fields = [...fields, { label: t("Wallet provider code"), value: "vendor_code" }];
		}

		fields = [
			...fields,
			{ label: t("Order ID"), value: "merchant_order_id" },
			{ label: t("Counterparty"), value: "exchanger_identifier" },
			{ label: t("Commission"), value: "total_commission" },
			{ label: t("Date"), value: "date" },
			{ label: t("Activation date"), value: "activated_date" },
			{ label: t("Label"), value: "label" },
			{ label: t("Status"), value: "status" },
			{ label: t("Transaction kind"), value: "type" },
			{ label: t("Transaction type"), value: "creation_type" },
			{ label: t("Archiving date"), value: "archiving_date" },
			{ label: t("Originality"), value: "originality" },
		];

		return fields;
	}, [hasProviderAccess]);

	const FORM_SCHEMA = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "wallet_type",
					label: t("Wallet type"),
					type: "Select",
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: "direction",
					label: t("Direction"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ value: "outgoing", label: t("Outgoing") },
						{ value: "ingoing", label: t("Ingoing") },
					],
				},
				{
					name: "wallet_hash_id",
					label: t("Wallet"),
					type: "Select",
					isLoading: getWalletsAPI.isLoading,
					options: ({ wallet_type }: FormStateType) => getWalletsOptions(wallet_type as string),
					existsIf: hasWalletsAccess,
					customOption: CustomWalletOption,
					disabled: ({ wallet_type }: FormStateType) => !wallet_type,
				},
				{
					name: "type",
					label: t("Transaction kind"),
					type: "MultiSelect",
					placeholder: t("All"),
					options: [
						{ label: t("Confirmed"), value: "0" },
						{ label: t("Forced"), value: "2" },
						{ label: t("Debug"), value: "1" },
					],
				},
				{
					name: "created",
					label: t("Period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: ({ activated, archiving_date }: FormStateType) =>
						activated === undefined && archiving_date === undefined,
					validation: (
						value: string,
						{ created, activated, archiving_date, status, archiving_status }: FormStateType
					) => {
						if (status === 0 && archiving_status === "withoutArchived" && created === undefined) {
							return t("Choose period");
						}

						if (!created && !activated && !archiving_date) {
							return t("Fill at least one period");
						}

						return undefined;
					},
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -150px)`,
					})),
				},
				{
					name: "creation_type",
					label: t("Transaction type"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Auto"), value: "auto" }, { label: t("Manual"), value: "manual" }],
				},
				{
					name: "activated",
					label: t("Activation period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: (date: Date) => date.getTime() >= new Date().getTime(),
					withTime: true,
					existsIf: ({ status }: FormStateType) => status !== 0,
					isRequired: ({ created, archiving_date }: FormStateType) =>
						created === undefined && archiving_date === undefined,
					validation: (value: string, { created, activated, archiving_date, status }: FormStateType) => {
						if (!created && !activated && !archiving_date && status !== 0) {
							return t("Fill at least one period");
						}

						return undefined;
					},
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -210px)`,
					})),
				},
				{
					name: "archiving_status",
					label: t("Transaction archiving status"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ label: t("Archived only"), value: "onlyArchived" },
						{ label: t("Not archived only"), value: "withoutArchived" },
					],
				},
				{
					name: "archiving_date",
					label: t("Archiving period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					// disabled: ({ archiving_status }) => archiving_status === "withoutArchived", // TODO: разобраться со странной проблемой disabled в DateRangePicker
					existsIf: ({ archiving_status }: FormStateType) => archiving_status !== "withoutArchived",
					isRequired: ({ created, activated }: FormStateType) => created === undefined && activated === undefined,
					validation: (value: string, { created, activated, archiving_date }: FormStateType) => {
						if (!created && !activated && !archiving_date) {
							return t("Fill at least one period");
						}

						return undefined;
					},
					customStyles: (() => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -260px)`,
					})),
				},
				{
					name: "originality",
					label: t("Originality"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Normal"), value: true }, { label: t("Scam"), value: false }],
				},
				{
					name: "status",
					label: t("Transaction status"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Activated"), value: 1 }, { label: t("Not activated"), value: 0 }],
				},
				{ name: "label", label: t("Label"), type: "TextInput" },
				{ name: "exchanger_identifier", label: t("Counterparty"), type: "TextInput" },
				{ name: "utc_0", label: t("Timezone UTC+00:00"), type: "Toggler" },
			],
		},
		{
			type: "AdditionalSettingsSection",
			elements: [
				{
					type: "CheckboxGroup",
					name: "fields",
					options: availableFields,
				},
			],
		},
		{
			type: "BottomSection",
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createTransactionsListReportAPI.isLoading,
					onSubmit: (formState: FormStateType) => createTransactionsListReportAPI.create(prepareFormData(formState)),
				},
			],
		},
	];

	const customElements = {
		ScrollBox: (props) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<ScrollBox scrollDirection="vertical" className={styles.scrollBox}>
						{children}
					</ScrollBox>
				)}
			/>
		),
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		AdditionalSettingsSection,
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: "xlsx", fields: [], utc_0: true }}
				customElements={customElements}
				onStateChange={(prevForm, newForm) => {
					if (prevForm.wallet_type !== newForm.wallet_type) {
						setWalletType(newForm.wallet_type);
					}
				}}
			/>
		</div>
	);
};

export default TransactionsList;

type prepareFormDataType = (rawFormData: FormStateType) => ListReportReqData;

type FormStateType = {
	readonly file_format: "xlsx" | "csv";
	readonly utc_0?: boolean;
	readonly wallet_type?: string;
	readonly direction?: "outgoing" | "ingoing";
	readonly wallet_hash_id?: string;
	readonly type?: readonly ("0" | "1" | "2")[];
	readonly originality?: boolean;
	readonly created?: {
		readonly from: string;
		readonly to: string;
	};
	readonly activated?: {
		readonly from: string;
		readonly to: string;
	};
	readonly archiving_date?: {
		readonly from: string;
		readonly to: string;
	};
	readonly label?: string;
	readonly creation_type?: "auto" | "manual";
	readonly archiving_status?: "onlyArchived" | "withoutArchived";
	readonly status?: 1 | 0;
	readonly exchanger_identifier?: string;
	readonly fields: readonly (
		| "wallet_identifier"
		| "transaction_number"
		| "vendor_code"
		| "merchant_order_id"
		| "exchanger_identifier"
		| "total_commission"
		| "date"
		| "activated_date"
		| "label"
		| "status"
		| "type"
		| "creation_type"
		| "archiving_date"
	)[];
};

const prepareFormData: prepareFormDataType = (rawFormData) => ({
	filters: {
		wallet_type: rawFormData.wallet_type,
		wallet_hash_id: rawFormData.wallet_hash_id,
		direction: rawFormData.direction,
		creation_type: rawFormData.creation_type,
		originality: rawFormData.originality,
		type: rawFormData.type,
		status: rawFormData.status,
		label: rawFormData.label,
		exchanger_identifier: rawFormData.exchanger_identifier,
		created_from: fromLocaleDate(rawFormData.created?.from) || undefined,
		created_to: fromLocaleDate(rawFormData.created?.to) || undefined,
		activated_from: fromLocaleDate(rawFormData.activated?.from) || undefined,
		activated_to: fromLocaleDate(rawFormData.activated?.to) || undefined,
		archiving_status: rawFormData.archiving_status,
		archiving_date_from: fromLocaleDate(rawFormData.archiving_date?.from),
		archiving_date_to: fromLocaleDate(rawFormData.archiving_date?.to),
		only_archived: rawFormData.archiving_status === "onlyArchived",
	},
	fields: rawFormData.fields,
	file_format: rawFormData.file_format,
	utc_0: rawFormData.utc_0,
});
