import { t } from "pay-kit";
export const maxLengthValidation = (limit: number) => (value: string) =>
	value?.length > limit ? t(`Max length - {{limit}}`, { limit }) : undefined;

export const lengthValidation = (limit: number) => (value: string) =>
	value?.length !== limit ? t(`Length - {{limit}}`, { limit }) : undefined;

export const minLengthValidation = (limit: number) => (value: string) =>
	value?.length < limit ? t(`Min length - {{limit}}`, { limit }) : undefined;

export const isRequired = (value: any) => (!value ? t("Field is required") : undefined);
export const isProxyRequired = (useProxy: boolean) => (value: unknown) =>
	useProxy && !value ? t("Choose at least one proxy") : undefined;

export const match = (regex: RegExp) => (value: any) =>
	!value ? undefined : regex.test(String(value)) ? undefined : t("Invalid format");

export const isEmail = match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const minValue = (limit: number) => (value: string) =>
	+value < limit ? t(`Min value - {{limit}}`, { limit }) : undefined;

export const maxValue = (limit: number) => (value: string) =>
	+value > limit ? t(`Max value - {{limit}}`, { limit }) : undefined;

export const isPassword = match(/^[\w\s!"#$%&'()*+,-./:;<=>?@[\]^`{|}~\\]+$/);

export const onlyAlphaNumericCharsAllowed = (value: string) =>
	!/^[\da-z]+$/i.test(value) ? t(`Only digits and letters`) : undefined;

export const onlyLatinSymbolsAllowed = (value: string) =>
	!/^[a-zA-Z\d]+$/.test(value) ? t("Only latin letters, numbers and symbols are allowed") : undefined;

export const optionalOnlyLatinSymbolsAllowed = (value: string) => {
	if (!value) {
		return undefined;
	}

	return !/^[a-zA-Z\d]+$/.test(value) ? t("Only latin letters, numbers and symbols are allowed") : undefined;
};

export const onlyDigitsAllowed = (value: string) => {
	if (value === undefined || value === null) {
		return undefined;
	}

	return !/^\d+$/.test(value) ? t("Only digits are allowed") : undefined;
};

export const isProviders = match(/^@[\w.-]+(?:, ?@[\w.-]+| @[\w.-]+)*$/);

export const alphaNumericWithSpec = match(/[a-zA-Z\d!"#$%&'()*+,-./:;<=>?@\[\]\\^_`{|}~\s]/);