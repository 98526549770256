import { BarcodeDetector as BarcodeDetectorPolyfill } from "barcode-detector";
import { t } from "pay-kit";
import { WalletTypes } from "utils/enums";

import { AccountType, ParserType } from "./enums";

// Handle UPI L fields by their type
// https://confluence.fixmost.com/pages/viewpage.action?pageId=125600078
export const handleUpiLFieldVisibleState = (
	// Состояние формы
	values: Record<string, unknown>,
	// Поля, которые содержат разные условия
	field:
		| "account_number"
		| "api_public_key"
		| "parser_type"
		| "api_private_key"
		| "project_id"
		| "username"
		| "email_username"
		| "email_password"
		| "password"
		| "codephrases"
		| "integration_type"
) => {
	const isUpiLIntegration = values.integration_type === `upi_l`;
	const isAPayIntegration = values.integration_type === `a_pay`;

	const isFreeChargeParser = values.parser_type === ParserType.PARSER_FREE_CHARGE;
	const isIndusinParser = values.parser_type === ParserType.PARSER_INDUSIN;
	const isFedNetParser = values.parser_type === ParserType.FEDNET;

	if (!values?.integration_type) {
		return false;
	}

	switch (field) {
		case "account_number": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return true;
				}
			}

			if (isAPayIntegration) {
				return false;
			}

			break;
		}

		case "api_public_key": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return false;
				}

				if (isIndusinParser) {
					return false;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			if (isAPayIntegration) {
				return true;
			}
			break;
		}

		case "parser_type": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return true;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}

		// addresses

		case "api_private_key": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return false;
				}

				if (isIndusinParser) {
					return false;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			return true;
			break;
		}

		case "project_id": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return false;
				}

				if (isIndusinParser) {
					return false;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			if (isAPayIntegration) {
				return true;
			}
			break;
		}

		case "email_password": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return false;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}

		case "email_username": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return false;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			if (isAPayIntegration) {
				return false;
			}

			// Если вообще парсер не выбран
			return false;

			break;
		}

		case "username": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return true;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}

		case "codephrases": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return false;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return false;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}

		case "password": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return false;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return true;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}

		case "upi_addresses": {
			if (isUpiLIntegration) {
				if (isFreeChargeParser) {
					return true;
				}

				if (isIndusinParser) {
					return true;
				}

				if (isFedNetParser) {
					return true;
				}
			}

			if (isAPayIntegration) {
				return false;
			}
			break;
		}
	}
};

export const getWalletParserTypes = (walletType: string, account_type?: AccountType) => {
	switch (walletType) {
		case WalletTypes.UPI_L:
			return [
				{
					value: ParserType.PARSER_FREE_CHARGE,
					label: t("Freecharge site parser"),
				},
				{
					value: ParserType.PARSER_INDUSIN,
					label: t("IndusInd site parser"),
				},
				{
					value: ParserType.FEDNET,
					label: t("Federal bank site parser"),
				},
			];
		case WalletTypes.UPI_S:
			return [
				{
					value: ParserType.PARSER_FREE_CHARGE,
					label: t("Freecharge site parser"),
				},
				{
					value: ParserType.PARSER_INDUSIN,
					label: t("IndusInd site parser"),
				},
				{
					value: ParserType.FEDNET,
					label: t("Federal bank site parser"),
				},
			];
		case WalletTypes.NAGAD:
		case WalletTypes.BKASH: {
			if (account_type === AccountType.MERCHANT) {
				return [
					{
						value: ParserType.PARSER_SITE,
						label: t("Site parser"),
					},
				];
			}

			return [
				{
					value: ParserType.PARSER_EMAIL,
					label: t("Email parser"),
				},
				{
					value: ParserType.PARSER_APP,
					label: t("App parser"),
				},
			];
		}
		case WalletTypes.PHONE_PE:
			return [
				{
					value: ParserType.PARSER_PHONE_PE_MOBILE_API,
					label: t("PhonePe Mobile API"),
				},
				{
					value: ParserType.PARSER_EMAIL,
					label: t("Email parser"),
				},
				{
					value: ParserType.PARSER_HDFC,
					label: t("HDFC site parser"),
				},
				{
					value: ParserType.PARSER_INDUSIN,
					label: t("IndusInd site parser"),
				},
			];
		case WalletTypes.IMPS_AB:
		case WalletTypes.UPI_AB:
			return [
				{
					value: ParserType.PARSER_EMAIL,
					label: t("Email parser"),
				},
				{
					value: ParserType.PARSER_AXIS,
					label: t("AXIS parser"),
				},
			];
		case WalletTypes.PAY_TM:
			return [
				{
					value: ParserType.MOBILE_API,
					label: t("Mobile API"),
				},
				{
					value: ParserType.PARSER_SITE,
					label: t("Site parser"),
				},
			];
	}
};

export const getBarcode = async (file: File) => {
	return new Promise((res, rej) => {
		const barcodeDetector = new BarcodeDetectorPolyfill();

		try {
			const fr = new FileReader();
			fr.readAsArrayBuffer(file as File);
			fr.onload = () => {
				barcodeDetector
					.detect(new Blob([fr.result as BlobPart]))
					.then((barcodes) => {
						const barcode = barcodes[0];

						if (barcode) {
							res(barcode.rawValue);
						} else {
							rej(t("Incorrect QR"));
						}
					})
					.catch((err) => {
						rej(err);
					});
			};
		} catch (err) {
			rej(err);
		}
	});
};

// 86400000 - Количество мсекунд в сутках
export const notLaterThanToday = (date) => date.getTime() <= new Date(new Date().getTime() - 86400000);

export const notLaterThanCreatedAt = (created_at: string) => (date) =>
	date.getTime() <= new Date(created_at).getTime() - 86400000;
